<template>
  <div>
    <transition name="backdrop" appear>
      <v-alert v-if="isPayoutMethodInvalid && !isPayoutAlertInvalid" color="white" :colored-border="true" class="deals-alert">
        <div class="deals-alert-text">
          <v-icon size="20" color="warning" class="mr-2 mb-1">{{ icons.mdiAlert }}</v-icon>
          <b class="deals-alert-warning">{{ $t('Warning!') }} </b>
          {{ $t('The payout method you\'ve added is not valid. Please check your payout details or add a valid payout method.') }}
          <router-link style="white-space: nowrap" :to="{name: 'user-market', params: {tab: 'payout-methods'}}">
            {{ $t('Update Payout Method') }}
          </router-link>
        </div>
        <div>
          <v-btn
            class="mr-2"
            icon
            small
            @click="isPayoutAlertInvalid = !isPayoutAlertInvalid"
          >
            <v-icon size="20">{{ icons.mdiWindowClose }}</v-icon>
          </v-btn>
        </div>
      </v-alert>
      <v-alert v-if="!isPayoutMethod && !isPayoutAlert && hasStore" color="white" :colored-border="true" class="deals-alert">
        <div class="deals-alert-text">
          <v-icon size="20" color="warning" class="mr-2 mb-1">{{ icons.mdiAlert }}</v-icon>
          <b class="deals-alert-warning">{{ $t('Warning!') }} </b>
          {{ $t('You have not added a payment method to your store. To receive payouts, please add at least one valid payment method.') }}
          <router-link style="white-space: nowrap" :to="{name: 'user-market', params: {tab: 'payout-methods'}}">
            {{ $t('Add Payout Method') }}
          </router-link>
        </div>
        <div>
          <v-btn
            class="mr-2"
            icon
            small
            @click="isPayoutAlert = !isPayoutAlert"
          >
            <v-icon size="20">{{ icons.mdiWindowClose }}</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </transition>
  </div>
</template>

<script>
import { mdiWindowClose, mdiAlert } from '@mdi/js';
import { computed, onBeforeMount } from '@vue/composition-api'
import store from '@/store'

export default {
  setup(props, {emit}) {
    const isPayoutAlertInvalid = computed({
      get: () => store.state.nav.isPayoutAlertInvalid,
      set: value => {
        store.commit('nav/SET_IS_PAYOUT_ALERT_INVALID', value)
      }
    })
    const isPayoutAlert = computed({
      get: () => store.state.nav.isPayoutAlert,
      set: value => {
        store.commit('nav/SET_IS_PAYOUT_ALERT', value)
      }
    })
    return {
        isPayoutAlertInvalid,
        isPayoutAlert,
        isPayoutMethodInvalid: computed(() => store.state.user.userResource.seller_payout_method_invalid),
        isPayoutMethod: computed(() => store.state.user.userResource.seller_payout_method),
        hasStore: computed(() => store.state.user.userResource.seller_has_store),
        icons: {
          mdiAlert,
          mdiWindowClose
        }
    }
  }
}
</script>
<style lang="scss" scoped>
.backdrop-leave-active,.content-leave-active {
  animation: fade-out .75s;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>