import axios from "@axios";

export default {
    namespaced: true,
    state: {
       profile: {},
       store: {},
       loading: true,
    },
    getters: {
        profile(state) {
            return state.profile
        },
        store(state) {
            return state.store
        },
        loading(state) {
            return state.loading
        }
    },
    mutations: {
        SET_PROFILE(state, value) {
            state.profile = value
        },
        SET_STORE(state, value) {
            state.store = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_PROFILE_STATUS(state, value) {
            state.profile.status = value
        },
        SET_PROFILE_ROLES(state, value) {
            state.profile.roles = value
        },
        SET_PROFILE_NAME(state, value) {
            state.profile.name = value
        },
        SET_PROFILE_EMAIL(state, value) {
            state.profile.email = value
        }
    },
    actions: {
        getUser(ctx, data) {
            ctx.commit('SET_LOADING', true)
            axios.get('admin/user/' + data.id).then(res => {
                ctx.commit('SET_PROFILE', res.data.data)
                ctx.commit('SET_STORE', res.data.data.market)
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        },
        updateUserStatus(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const userProfile = Object.assign({}, data.value, {_method: 'put'})
            axios.post('admin/update-user-status/' + userProfile.id, userProfile)
            .then(res => {
                ctx.dispatch('getUser', {id: userProfile.id});
                ctx.commit('SET_LOADING', false)
            })
            .catch(e => console.log(e))
        },
        updateUserRole(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const userProfile = Object.assign({}, data.value, {_method: 'put'})
            axios.post('admin/update-user-roles/' + userProfile.id, userProfile)
            .then(res => {
                ctx.dispatch('getUser', {id: userProfile.id});
                ctx.commit('SET_LOADING', false)
            })
            .catch(e => console.log(e))
        },
        updateUser(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const userProfile = Object.assign({}, data.value, {_method: 'put'})
            axios.post('admin/update-user/' + userProfile.id, userProfile)
            .then(res => {
                ctx.dispatch('getUser', {id: userProfile.id});
                ctx.commit('SET_LOADING', false)
            })
            .catch(e => console.log(e))
        },
        updateUserStore(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const userStore = Object.assign({}, data.value, {_method: 'put'})
            axios.post('admin/update-user-store/' + userStore.id, userStore)
            .then(res => {
                ctx.dispatch('getUser', {id: userStore.userId});
                ctx.commit('SET_LOADING', false)
            })
            .catch(e => console.log(e))
        }
    },

}
