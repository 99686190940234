<template>
  <v-menu
      offset-y
      left
      nudge-bottom="22"
      :elevation="$vuetify.theme.dark ? 9 : 8"
      content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
          :content="unread_count"
          :value="unread_count"
          color="success"
          class="mr-3"
          offset-x="13"
          offset-y="8"
      >
        <v-icon
            v-bind="attrs"
            v-on="on"
        >
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
          class="ps-user-notifications"
          :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
              class="d-flex"
              link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">{{ $t('Notifications') }}</span>
              <div>
                <v-btn  v-if="unread_count" rounded x-small color="primary" text class="mr-2" @click="markAsRead">{{ $t('mark as read') }}
                </v-btn>
                <v-chip
                    class="v-chip-light-bg primary--text font-weight-semibold"
                    small
                >
                  {{ unread_count }} New
                </v-chip>
              </div>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(notification, index) in notifications">
            <v-list-item
                :key="notification.id"
                link
                @click="openLink(notification.link, notification.params, notification.id)"
            >
              <!-- Avatar -->
              <v-list-item-avatar>
                <v-icon large :color="notification.iconColor">{{
                    notification.icon
                  }}
                </v-icon>
              </v-list-item-avatar>

              <!-- Content -->
              <v-list-item-content>
                <v-list-item-title class="text-sm font-weight-semibold" v-html="notification.text">
                </v-list-item-title>
                <!--                <v-list-item-subtitle class="text-sm">-->
                <!--                  {{ notification.subtitle }}-->
                <!--                </v-list-item-subtitle>-->
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ notification.time }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item
              key="read-all-btn"
              class="read-all-btn-list-item"
          >
            <v-btn
                block
                color="primary"
                @click="$router.push({name: 'notifications'})"
            >
              {{ $t('Read All Notifications') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import {mdiAccountTie, mdiAlarm, mdiBellOutline, mdiCashRefund, mdiPackageVariant} from '@mdi/js'

// 3rd Party
import {PerfectScrollbar} from 'vue2-perfect-scrollbar'
import {computed, getCurrentInstance} from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {mapNotifications} from "@core/utils/notifications";

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    store.dispatch('notifications/getNotifications', {paginate: 5})
    const notifications = computed(() => {
          return mapNotifications(store.state.notifications.notifications)
        }
    )
    const openLink = (to, params = {}, id) => {
      axios.post('mark-as-read', {ids: [id], _method: 'put'})
          .then(res => {
            store.dispatch('notifications/getNotifications', {paginate: 5})
            if (to == "user-store") {
              axios.get('/auth/me').then(response => {
                const user = response.data.data
                const {ability: userAbility} = user
                vm.$ability.update(userAbility)
                localStorage.setItem('userAbility', JSON.stringify(userAbility))
                router.push({name: to})
              })
            } else {
              router.push({name: to, params})
            }
          })
    }

    const loading = computed(() => {
      return store.state.notifications.loading
    })

    const unread_count = computed(() => {
      return store.state.notifications.unread_count
    })

    const perfectScrollbarOptions = {
      maxScrollbarLength: 100,
      wheelPropagation: false,
    }

    const markAsRead = () => {
      axios.post('mark-as-read', {ids: notifications.value.map(nt => nt.id), _method: 'put'})
          .then(res => {
            store.dispatch('notifications/getNotifications', {paginate: 5})
          })
    }
    return {
      loading,
      notifications,
      perfectScrollbarOptions,
      unread_count,
      markAsRead,
      openLink,
      icons: {
        mdiBellOutline,
        mdiAlarm,
        mdiCashRefund
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 84);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
