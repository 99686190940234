import {i18n} from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import Notifications from 'vue-notification'
import GAuth from 'vue-google-oauth2'
import appConfig from "@appConfig";
import echo from "./plugins/sockets/sockets";
import Breadcrumbs from "@/views/Breadcrumbs";
import RoleTitle from "@/views/RoleTitle";
import VueGtag from "vue-gtag";
import {StripePlugin} from '@vue-stripe/vue-stripe';
import {empty} from "leaflet/src/dom/DomUtil";
import VueIntercom from '@mathieustan/vue-intercom';
import * as intercom from "@/plugins/tracking/intercom";
import {initAmplitude, setUserProperties, logEventAmplitude} from '@/plugins/tracking/amplitude'
import {updateUserResource} from "@/plugins/tracking/user-resource";
import {identifyMixPanel, initMixPanel, logEventMixPanel} from "@/plugins/tracking/mixpanel";

const gauthOption = {
    clientId: appConfig.googleId
}

const stripeOptions = {
    pk: appConfig.stripePubKey,
    stripeAccount: appConfig.stripeAccount,
    // apiVersion: process.env.API_VERSION,
    locale: 'en',
};

const notificationOptions = {
    position: 'bottom right'
}

Vue.component('breadcrumbs', Breadcrumbs)
Vue.component('account-role-title', RoleTitle)
Vue.use(VueMeta)
Vue.use(StripePlugin, stripeOptions);
Vue.use(GAuth, gauthOption)
Vue.use(Notifications, notificationOptions)
Vue.use(VueIntercom, {appId: appConfig.intercomId});
if (appConfig.gTag && !empty(appConfig.gTag))
    Vue.use(VueGtag, {
        config: {id: appConfig.gTag}
    })
Vue.config.productionTip = false

Vue.prototype.$echo = echo

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App),
    created() {
        updateUserResource(this).then(() => {
            if (this.$intercom) {
                this.$intercom.once('ready', this.bootIntercom);
            }
            this.firstRunAmplitude()
            this.firstRunMixPanel()
        })
    },
    methods: {
        bootIntercom() {
            intercom.initIntercom(this.$intercom)
        },
        firstRunAmplitude() {
            initAmplitude()
            setUserProperties()
        },
        firstRunMixPanel() {
            initMixPanel()
            identifyMixPanel()
        }
    },
}).$mount('#app')
