import {
    mdiAccountCheck,
    mdiAccountMultiple,
    mdiArchive, mdiCashMultiple,
    mdiClipboardCheck, mdiCogs,
    mdiCurrencyUsd,
    mdiNoteMultiple,
    mdiViewDashboard
} from "@mdi/js";

export default [
    {
        subheader: 'Admin',
        action: 'product approve'
    },
    {
        title: 'Dashboard',
        key: 'admin-dashboard',
        to: 'admin-dashboard',
        icon: mdiViewDashboard,
        action: 'all orders view'
    },
    {
        title: 'Users',
        key: 'users',
        icon: mdiAccountMultiple,
        action: 'users view',
        to: 'users',
        children: [
            {
                title: "All Users",
                action: 'users view',
                to: { name: 'users', params: { tab: 'all' } },
            },
            {
                title: "Buyers",
                action: 'users view',
                to: { name: 'users', params: { tab: 'buyer' } },
            },
            {
                title: "Sellers",
                action: 'users view',
                to: { name: 'users', params: { tab: 'seller' } },
            },
            {
                title: "Moderators",
                action: 'users view',
                to: { name: 'users', params: { tab: 'moderator' } },
            }
        ]
    },
    {
        title: 'Upgrade Requests',
        to: 'seller-requests',
        icon: mdiAccountCheck,
        action: 'upgrade to seller view',
        badge: 'upgrade_to_seller_request_count',
        badgeColor: 'success'
    },
    {
        title: 'Orders',
        key: 'admin-orders',
        icon: mdiClipboardCheck,
        to: 'admin-orders-list',
        action: 'all orders view'
    },
    {
        title: 'Refunds',
        key: 'admin-refunds',
        icon: mdiCurrencyUsd,
        to: 'admin-refunds-list',
        action: 'all refunds view',
        badge: 'refunds_escalated_to_admin_count',
        badgeColor: 'success',
        children: [
            {
                title: 'All Refunds',
                action: 'all refunds view',
                to: {name: 'admin-refunds-list', params: {tab: 'all'}}
            },
            {
                title: 'Requested',
                action: 'all refunds view',
                to: {name: 'admin-refunds-list', params: {tab: 'refund-requests'}}
            },
            {
                title: 'Escalated',
                action: 'all refunds view',
                to: {name: 'admin-refunds-list', params: {tab: 'escalated'}},
                badge: 'refunds_escalated_to_admin_count',
                badgeColor: 'success',
            },
            {
                title: 'Refunded',
                action: 'all refunds view',
                to: {name: 'admin-refunds-list', params: {tab: 'refunded'}}
            }
        ]
    },
    {
        title: 'Inventory',
        key: 'admin-inventory',
        icon: mdiArchive,
        to: 'admin-products-list',
        action: 'product approve',
        badge: 'products_on_review_count',
        badgeColor: 'success',
        children: [
            {
                title: 'All Products',
                action: 'all products view',
                to: {name: 'admin-products-list', params: {tab: 'all'}}
            },
            {
                title: 'Active',
                action: 'all products view',
                to: {name: 'admin-products-list', params: {tab: 'active'}}
            },
            {
                title: 'Inactive',
                action: 'all products view',
                to: {name: 'admin-products-list', params: {tab: 'inactive'}}
            },
            {
                title: 'Drafts',
                action: 'all products view',
                to: { name: 'admin-products-list', params: { tab: 'drafts' } },
            },
            {
                title: 'Rejected',
                action: 'all products view',
                to: { name: 'admin-products-list', params: { tab: 'rejected' } },
            },
            {
                title: 'Unverified',
                action: 'product approve',
                to: {name: 'admin-products-list', params: {tab: 'unverified'}},
                badge: 'products_on_review_count',
                badgeColor: 'success',
            },
            {
                title: 'Unverified New',
                action: 'all products view',
                to: {name: 'admin-products-list', params: {tab: 'unverified-new'}},
                badge: 'products_on_review_count',
                badgeColor: 'success',
            }
        ]
    },
    {
        title: 'Transactions',
        key: 'admin-transactions',
        icon: mdiNoteMultiple,
        action: 'transactions view',
        children: [
            {
                title: 'All Transactions',
                action: 'transactions view',
                to: {name: 'admin-transactions', params: {tab: 'all'}}
            },
            {
                title: 'Top Ups',
                action: 'transactions view',
                to: {name: 'admin-transactions', params: {tab: 'top-ups'}}
            },
            {
                title: 'Refunds',
                action: 'transactions view',
                to: {name: 'admin-transactions', params: {tab: 'refunds'}}
            },
            {
                title: 'Payouts',
                action: 'transactions view',
                to: {name: 'admin-transactions', params: {tab: 'payouts'}}
            },
            {
                title: 'MP Profit',
                action: 'transactions view',
                to: {name: 'admin-transactions', params: {tab: 'mp-profit'}}
            }
        ]
    },
    {
        title: 'Payouts',
        icon: mdiCashMultiple,
        action: 'withdrawal approve',
        to: 'admin-payouts',
        children: [
            {
                title: 'Pending',
                action: 'withdrawal approve',
                to: {name: 'admin-payouts', params: {tab: 'pending'}}
            },
            {
                title: 'Paid',
                action: 'withdrawal approve',
                to: {name: 'admin-payouts', params: {tab: 'paid'}}
            }
        ]
    },
    {
        title: 'MP Settings',
        icon: mdiCogs,
        to: 'roles-and-permissions',
        action: 'permissions edit',
        children: [
            {
                title: 'Permissions',
                to: { name: 'roles-and-permissions', params: { tab: 'permissions' } },
                action: 'permissions edit',
            },
            {
                title: 'Fees',
                action: 'permissions edit',
            },
            {
                title: 'Emails',
                action: 'permissions edit',
            },
            {
                title: 'Settings',
                action: 'permissions edit',
            },
            {
                title: 'Blacklist',
                key: 'admin-suppliers-blacklist',
                action: 'all blacklist',
                to: 'admin-suppliers-blacklist'
            }
        ]
    }
]