import axios from "@axios";

export default {
    namespaced: true,
    state: {
        connectedAmzAccByRegions: {},
        marketplaceByRegion: {},
        loading: false,
        marketplaces: []
    },
    getters: {
        connectedAmzAccByRegions(state) {
            return state.connectedAmzAccByRegions
        },
        marketplaceByRegion(state) {
            return state.marketplaceByRegion
        },
        loading(state) {
            return state.loading
        },
        marketplaces(state) {
            return state.marketplaces
        }
    },
    mutations: {
        SET_CONNECTED_AMZ_ACC_BY_REGIONS(state, value) {
            state.connectedAmzAccByRegions = value
        },
        SET_MARKETPLACE_BY_REGION(state, value) {
            state.marketplaceByRegion = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_MARKETPLACES(state, value) {
            state.marketplaces = value
        },
    },
    actions: {
        async connectedAmzAccounts(ctx) {
            ctx.commit('SET_LOADING', true)
            return axios.get('c-amz-acc-by-r').then(res => {
                ctx.commit('SET_CONNECTED_AMZ_ACC_BY_REGIONS', res.data.connectedAccounts)
                ctx.commit('SET_MARKETPLACE_BY_REGION', res.data.marketplacesByRegion)
                ctx.commit('SET_MARKETPLACES', res.data.marketplaces)
                ctx.commit('SET_LOADING', false)
            }).catch(err => {
                ctx.commit('SET_LOADING', false)
            })
        }
    },

}
