import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import createPersistedState from "vuex-persistedstate"
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import nav from "./app/nav";
import filter from "./app/filter";
import product from "./app/product";
import user from "./app/user";
import pagination from "./app/pagination";
import profile from "./app/profile";
import imports from "./app/imports";
import payouts from "./app/payouts";
import account from "./app/account";
import connect from "./app/connect";
import market from "./app/market";
import notifications from "./app/notifications"
import cart from "./app/cart";
import payment from "./app/payment";
import order from "@/store/app/order";
Vue.use(Vuex)

const saveState = createPersistedState({
  paths: ['nav', 'user.userResource', 'filter.savedFilterData']
})

export default new Vuex.Store({
  plugins: [saveState],
  modules: {
    appConfig: appConfigStoreModule,
    connect,
    app,
    filter,
    nav,
    pagination,
    product,
    user,
    imports,
    payouts,
    account,
    market,
    notifications,
    cart,
    payment,
    order,
    profile
  },
})
