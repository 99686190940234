import {mdiAccountTie, mdiCashRefund, mdiPackageVariant} from "@mdi/js";

export const mapNotifications = array => {
return array.map((nt) => {
        let ntNew = {
            icon: '',
            iconColor: '',
            text: '',
            time: nt.created_at,
            id: nt.id,
            link: '',
            readAt: nt.read_at,
            params: {}
        }
        switch (nt.type) {
            case 'App\\Notifications\\ProductOnReviewNotification':
                ntNew.icon = mdiPackageVariant
                ntNew.iconColor = 'info'
                ntNew.text = 'Product added to review list'
                ntNew.link = 'admin-products-list'
                ntNew.params = {tab: 'unverified'}
                break;
            case 'App\\Notifications\\ProductApprovalNotification':
                ntNew.icon = mdiPackageVariant
                switch (nt.data.status) {
                    case 2:
                        ntNew.icon = mdiPackageVariant
                        ntNew.iconColor = 'success'
                        ntNew.text = `Product <span class="primary--text">${nt.data.asin}</span> approved`
                        ntNew.link = 'seller-product'
                        ntNew.params = {id: nt.data.id}
                        break;
                    case 3:
                        ntNew.icon = mdiPackageVariant
                        ntNew.iconColor = 'warning'
                        ntNew.text = `Product <span class="primary--text">${nt.data.asin}</span> rejected`
                        ntNew.link = 'seller-product'
                        ntNew.params = {id: nt.data.id}
                        break;
                    case 4:
                        ntNew.icon = mdiPackageVariant
                        ntNew.iconColor = 'error'
                        ntNew.text = `Product <span class="primary--text">${nt.data.asin}</span> banned`
                        ntNew.link = 'seller-product'
                        ntNew.params = {id: nt.data.id}
                        break;
                }
                break;
            case 'App\\Notifications\\SellerRequestNotification':
                ntNew.icon = mdiAccountTie
                ntNew.iconColor = 'info'
                ntNew.text = 'Seller request added to review list'
                ntNew.link = 'seller-requests'
                break;
            case 'App\\Notifications\\SellerApprovalNotification':
                switch (nt.data.status) {
                    case 1:
                        ntNew.icon = mdiAccountTie
                        ntNew.iconColor = 'success'
                        ntNew.text = `Seller request approved, click to start selling`
                        ntNew.link = 'user-store'
                        break;
                    case 2:
                        ntNew.icon = mdiAccountTie
                        ntNew.iconColor = 'error'
                        ntNew.text = `Seller request declined, click to see details`
                        ntNew.link = 'user-account'
                        break;
                }
                break;
            case 'App\\Notifications\\EscalatedRefundNotification':
                ntNew.icon = mdiCashRefund
                ntNew.iconColor = 'warning'
                ntNew.text = `Refund escalated to admin`
                ntNew.link = 'admin-order'
                ntNew.params = {id: nt.data.order_id}
                break;
            case 'App\\Notifications\\SellerRefundActionNotification':
                switch (nt.data.status) {
                    case 3:
                        ntNew.icon = mdiCashRefund
                        ntNew.iconColor = 'error'
                        ntNew.text = `Refund <span class="primary--text">${nt.data.order_id}</span> declined by seller`
                        ntNew.link = 'order'
                        ntNew.params = {id: nt.data.order_id}
                        break;
                    case 4:
                        ntNew.icon = mdiCashRefund
                        ntNew.iconColor = 'success'
                        ntNew.text = `Refund <span class="primary--text">${nt.data.order_id}</span> refunded by seller`
                        ntNew.link = 'order'
                        ntNew.params = {id: nt.data.order_id}
                        break;
                }
                break;
            case 'App\\Notifications\\RefundCreatedNotification':
                ntNew.icon = mdiCashRefund
                ntNew.iconColor = 'warning'
                ntNew.text = `You have new refund request <span class="primary--text">${nt.data.order_id}</span>`
                ntNew.link = 'seller-order'
                ntNew.params = {id: nt.data.order_id}
                break;
            case 'App\\Notifications\\AdminRefundActionNotification':
                switch (nt.data.status) {
                    case 6:
                        switch (nt.data.role) {
                            case 'buyer':
                                ntNew.icon = mdiCashRefund
                                ntNew.iconColor = 'secondary'
                                ntNew.text = `Refund declined by admin <span class="primary--text">${nt.data.order_id}</span>`
                                ntNew.link = 'order'
                                ntNew.params = {id: nt.data.order_id}
                                break;
                            case 'seller':
                                ntNew.icon = mdiCashRefund
                                ntNew.iconColor = 'error'
                                ntNew.text = `Refund declined by admin <span class="primary--text">${nt.data.order_id}</span>`
                                ntNew.link = 'seller-order'
                                ntNew.params = {id: nt.data.order_id}
                                break;
                        }
                        break;
                    case 7:
                        switch (nt.data.role) {
                            case 'buyer':
                                ntNew.icon = mdiCashRefund
                                ntNew.iconColor = 'success'
                                ntNew.text = `Order refunded by admin <span class="primary--text">${nt.data.order_id}</span>`
                                ntNew.link = 'order'
                                ntNew.params = {id: nt.data.order_id}
                                break;
                            case 'seller':
                                ntNew.icon = mdiCashRefund
                                ntNew.iconColor = 'warning'
                                ntNew.text = `Order refunded by admin <span class="primary--text">${nt.data.order_id}</span>`
                                ntNew.link = 'seller-order'
                                ntNew.params = {id: nt.data.order_id}
                                break;
                        }
                        break;
                }
                break;
            case 'App\\Notifications\\OrderItemPaidNotification':
                ntNew.icon = mdiPackageVariant
                ntNew.iconColor = 'warning'
                ntNew.text = `you have new order  <span class="primary--text">${nt.data.order_id}</span>`
                ntNew.link = 'seller-order'
                ntNew.params = {id: nt.data.order_id}
                break;
        }
        return ntNew
    })
}