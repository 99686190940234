import axios from "@axios";
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";
import router from '@/router'

export default {
    namespaced: true,
    state: {
        imports: [],
        total: 0,
        loading: true
    },
    getters: {
        imports(state) {
            return state.imports
        },
        total(state) {
            return state.total
        },
        loading(state) {
            return state.loading
        }
    },
    mutations: {
        SET_IMPORTS(state, value) {
            state.imports = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_TOTAL(state, value) {
            state.total = value
        }
    },
    actions: {
        getImports(ctx) {
            ctx.commit('SET_LOADING', true)
            const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = ctx.rootGetters['pagination/products'][router.currentRoute.params.tab]
            const URL = router.currentRoute.name === 'admin-products-user-store-list' ? 'admin/product-uploads/' + router.currentRoute.params.store_id : 'product-uploads'
            let params = {
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'DESC' : 'ASC'
            }
            axios.get(URL, {
                params
            }).then(res => {
                ctx.commit('SET_IMPORTS', res.data.data)
                ctx.commit('SET_TOTAL', res.data.meta.total)
                ctx.commit('SET_LOADING', false)
                ctx.commit("pagination/SET_PRODUCTS_PAGINATION", {
                    uploaded: {itemsPerPage: params.pagination, page, lastPage: res.data.meta.last_page}
                }, {root: true})
            }).catch(e => {
                ctx.commit('SET_LOADING', false)
            })
        }
    }

}
