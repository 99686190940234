import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    name: 'Deals',
    logo: require('./src/assets/images/icons/project-icons/logo_deals_beta.png'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'boxed',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: true,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'static', // fixed, static, hidden
  },
  themes: {
    light: {
      'primary': '#9155FD',
      'accent': '#0d6efd',
      'secondary': '#8A8D93',
      'on-secondary': '#fff',
      'success': '#56CA00',
      'info': '#16B1FF',
      'warning': '#FFB400',
      'error': '#FF4C51',
      'on-primary': '#FFFFFF',
      'on-success': '#FFFFFF',
      'on-warning': '#FFFFFF',
      'background': '#F4F5FA',
      'on-background': '#3A3541',
      'on-surface': '#3A3541',
      'grey-50': '#FAFAFA',
      'grey-100': '#F5F5F5',
      'grey-200': '#EEEEEE',
      'grey-300': '#E0E0E0',
      'grey-400': '#BDBDBD',
      'grey-500': '#9E9E9E',
      'grey-600': '#757575',
      'grey-700': '#616161',
      'grey-800': '#424242',
      'grey-900': '#212121',
      'perfect-scrollbar-thumb': '#DBDADE',
    },
    dark: {
      'primary': '#9155FD',
      'accent': '#0d6efd',
      'secondary': '#8A8D93',
      'on-secondary': '#fff',
      'success': '#56CA00',
      'info': '#16B1FF',
      'warning': '#FFB400',
      'error': '#FF4C51',
      'on-primary': '#FFFFFF',
      'on-success': '#FFFFFF',
      'on-warning': '#FFFFFF',
      'background': '#28243D',
      'on-background': '#E7E3FC',
      'surface': '#312D4B',
      'on-surface': '#E7E3FC',
      'grey-50': '#2A2E42',
      'grey-100': '#2F3349',
      'grey-200': '#4A5072',
      'grey-300': '#5E6692',
      'grey-400': '#7983BB',
      'grey-500': '#8692D0',
      'grey-600': '#AAB3DE',
      'grey-700': '#B6BEE3',
      'grey-800': '#CFD3EC',
      'grey-900': '#E7E9F6',
      'perfect-scrollbar-thumb': '#4A5072',
    },
  },
}

export default themeConfig
