import axios from "@axios";

export default {
    namespaced: true,
    state: {
        paymentMethods: [],
        PMLoading: true,
        selectedPM: null
    },
    getters: {
        paymentMethods(state) {
            return state.paymentMethods
        },
        PMLoading(state) {
            return state.PMLoading
        },
        selectedPM(state) {
            return state.selectedPM
        }
    },
    mutations: {
        SET_PAYMENT_METHODS(state, value) {
            state.paymentMethods = value
        },
        SET_PM_LOADING(state, value) {
            state.PMLoading = value
        },
        SET_SELECTED_PM(state, value) {
            state.selectedPM = value
        }
    },
    actions: {
        getPaymentMethods(ctx, data = {}) {
            ctx.commit('SET_PM_LOADING', true)
            axios.get("/payment-methods").then(res => {
                    ctx.commit('SET_PAYMENT_METHODS', res.data.data)
                    ctx.commit('SET_PM_LOADING', false)
                })
                .catch(err => {
                    ctx.commit('SET_PM_LOADING', false)
                })
        },
        setSelectedPM(ctx, data) {
            ctx.commit('SET_SELECTED_PM', data)
        }
    },

}
