import mixpanel from 'mixpanel-browser';
import appConfig from "@appConfig";
import store from "@/store";

let distinct_id

export function initMixPanel() {
    if (appConfig.mixPanelToken) {
        mixpanel.init(appConfig.mixPanelToken, {
            'loaded': function () {
                distinct_id = mixpanel.get_distinct_id();
            }
        })
    }
}

export function identifyMixPanel() {
    if (appConfig.mixPanelToken) {
        const userResource = store.state.user.userResource
        if (userResource.id && userResource.id) {
            mixpanel.identify(userResource.id)
            mixpanel.register(Object.assign(userResource, {$email: userResource.email}))
        }
    }
}

export function logEventMixPanel(event, props) {
    if (appConfig.mixPanelToken) {
        let trackEvent = setInterval(function () {
            if (distinct_id) {
                clearInterval(trackEvent);
                mixpanel.track(event, props)
            }
        }, 500);
    }
}
