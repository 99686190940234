import axios from "@axios"
import router from '@/router'

export default {
    namespaced: true,
    state: {
        market: null,
        loading: true,
        balance: null,
        payoutsLoading: true,
        balanceLoading: true,
        payoutMethodList: [],
        payoutsList: []
    },
    getters: {
        market(state) {
            return state.market
        },
        loading(state) {
            return state.loading
        },
        payoutsLoading(state) {
            return state.payoutsLoading
        },
        balance(state) {
            return state.balance
        },
        payoutMethodList(state) {
            return state.payoutMethodList
        },
        payoutsList(state) {
            return state.payoutsList
        },
        balanceLoading(state) {
            return state.balanceLoading
        }
    },
    mutations: {
        SET_MARKET(state, value) {
            state.market = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_PAYOUTS_LOADING(state, value) {
            state.payoutsLoading = value
        },
        SET_BALANCE(state, data) {
            state.balance = data.amount
        },
        SET_BALANCE_LOADING(state, value) {
            state.balanceLoading = value
        },
        SET_PAYOUT_METHOD_LIST(state, value) {
            state.payoutMethodList = value
        },
        SET_PAYOUTS_LIST(state, value) {
            state.payoutsList = value
        }
    },
    actions: {
        getMarket(ctx, data = {}) {
            ctx.commit('SET_LOADING', true)
            axios.get('market')
                .then(res => {
                    ctx.commit('SET_MARKET', res.data.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch(err => {
                    if(err.response.status === 404) {
                        ctx.commit('SET_LOADING', false)
                        router.push({name: 'user-market', params:{tab:'all'}, query:{t:new Date().getTime()}})
                    }
                })
        },
        getStoreBalance(ctx, data = {}) {
            ctx.commit('SET_BALANCE_LOADING', true)
            axios.get('store-balance').then(res => {
                ctx.commit('SET_BALANCE', {amount: res.data})
                ctx.commit('SET_BALANCE_LOADING', false)
            }).catch(err => {
                ctx.commit('SET_BALANCE_LOADING', false)
            })
        },
        getDefaultPayoutMethodList(ctx, data = {}) {
            axios.get('payout-method-list').then(res => {
                ctx.commit('SET_PAYOUT_METHOD_LIST', res.data)
            }).catch()
        },
        getPayoutMethodList(ctx, data = {}) {
            ctx.commit('SET_PAYOUTS_LOADING', true)
            axios.get('payouts-list').then(res => {
                ctx.commit('SET_PAYOUTS_LIST', res.data.data)
                ctx.commit('SET_PAYOUTS_LOADING', false)
            }).catch(err => {
                ctx.commit('SET_PAYOUTS_LOADING', false)
            })
        }
    },

}
