<template>
    <transition name="fade">
        <div id="pagetop" class="top-button" @click="toTop()">
            <v-icon
              size="35"
              color="white"
            >
              {{ icons.mdiChevronUp }}
            </v-icon>
        </div>
    </transition>
</template>

<script>
import { mdiChevronUp } from '@mdi/js';
export default {
    setup(props) {
        const toTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
        return {
            toTop,
            icons: {
                mdiChevronUp
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>