import {
    mdiArchive, mdiClipboardCheck,
    mdiClipboardPlay,
    mdiCurrencyUsd,
    mdiNoteMultiple,
    mdiViewDashboard
} from '@mdi/js';

export default [
    {
        subheader: 'Store',
        action: 'seller panel view'
    },
    {
        title: 'Dashboard',
        key: 'dashboard',
        to: 'dashboard',
        icon: mdiViewDashboard,
        action: 'seller panel view'
    },
    {
        title: 'Inventory',
        key: 'seller-inventory',
        icon: mdiArchive,
        action: 'products view',
        to: 'seller-products',
        badge: 'rejected_products_count',
        badgeColor: 'error',
        children: [
            {
                title: 'All Products',
                action: 'products view',
                to: { name: 'seller-products', params: { tab: 'all' } },
            },
            {
                title: 'Active',
                action: 'products view',
                to: { name: 'seller-products', params: { tab: 'active' } },
            },
            {
                title: 'Inactive',
                action: 'products view',
                to: { name: 'seller-products', params: { tab: 'inactive' } },
            },
            {
                title: 'Drafts',
                action: 'products view',
                to: { name: 'seller-products', params: { tab: 'drafts' } },
            },
            {
                title: 'Rejected',
                action: 'products view',
                to: { name: 'seller-products', params: { tab: 'rejected' } },
                badge: 'rejected_products_count',
                badgeColor: 'error',
            },
            {
                title: 'Uploaded',
                action: 'products view',
                to: { name: 'seller-products', params: { tab: 'uploaded' } },
            }
        ]
    },

    {
        title: 'Refunds',
        key: 'seller-refunds',
        icon: mdiCurrencyUsd,
        to: 'seller-refunds-list',
        action: 'refund view',
        badge: 'refunds_requested_count',
        badgeColor: 'error',
        children: [
            {
                title: 'All Refunds',
                action: 'refund view',
                to: {name: 'seller-refunds-list', params: {tab: 'all'}}
            },
            {
                title: 'Refund Requests',
                action: 'refund view',
                to: {name: 'seller-refunds-list', params: {tab: 'refund-requests'}},
                badge: 'refunds_requested_count',
                badgeColor: 'error',
            },
            {
                title: 'Refunded',
                action: 'refund view',
                to: {name: 'seller-refunds-list', params: {tab: 'refunded'}}
            }
        ]
    },
    {
        title: 'Orders',
        icon: mdiClipboardCheck,
        key: 'seller-orders',
        to: 'seller-orders-list',
        action: 'orders view',
    },
    {
        title: 'Transactions',
        key: 'seller-transactions',
        icon: mdiNoteMultiple,
        action: 'orders view',
        to: 'store-transactions',
        children: [
            {
                title: 'All Transactions',
                to: {name: 'store-transactions', params: { tab: 'all' }},
                key: 'seller-transactions',
                action: 'orders view',
            },
            {
                title: 'Orders',
                to: {name: 'store-transactions', params: { tab: 'orders' }},
                key: 'seller-transaction-orders',
                action: 'orders view',
            },
            {
                title: 'Refunds',
                to: {name: 'store-transactions', params: { tab: 'refunds' }},
                key: 'seller-transaction-refunds',
                action: 'orders view',
            },
            {
                title: 'Payouts',
                to: {name: 'store-transactions', params: { tab: 'payouts' }},
                key: 'seller-transaction-refunds',
                action: 'orders view',
            }
        ]
    },
    {
        title: 'Store Account',
        icon: mdiClipboardPlay,
        action: 'market create',
        to: 'user-market',
        children: [
            {
                title: 'Store Settings',
                action: 'market create',
                to: { name: 'user-market', params: { tab: 'settings' } },
            },
            {
                title: 'Payout Methods',
                action: 'market create',
                to: { name: 'user-market', params: { tab: 'payout-methods' } },
            }
        ]
    }
]