import axios from "@axios";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        cartItems: [],
        selectedCartItems: [],
        miniCartItems: [],
        cartIds:[],
        buyNowId: null,
        selectedCartIds:[],
        cartLoading: true,
        miniCartLoading: true,
        useBalance: true,
        selectedPM: null,
        duplicates: [],
        doneModal: false,
        minTransactionAmount: null
    },
    getters: {
        minTransactionAmount(state) {
            return state.minTransactionAmount
        },
        doneModal(state) {
            return state.doneModal
        },
        cartIds(state) {
            return state.cartIds
        },
        cartItems(state) {
            return state.cartItems
        },
        miniCartItems(state) {
            return state.miniCartItems
        },
        cartLoading(state) {
            return state.cartLoading
        },
        miniCartLoading(state) {
            return state.miniCartLoading
        },
        useBalance(state) {
            return state.useBalance
        },
        selectedPM(state) {
            return state.selectedPM
        },
        duplicates(state) {
            return state.duplicates
        }
    },
    mutations: {
        SET_MIN_TRANSACTION_AMOUNT(state, value) {
            state.minTransactionAmount = value
        },
        SET_DONE_MODAL(state, value) {
            state.doneModal = value
        },
        SET_USE_BALANCE(state, value) {
            state.useBalance = value
        },
        SET_CART_ITEMS(state, value) {
            state.cartItems = value
            state.cartLoading = false
        },
        SET_MINI_CART_ITEMS(state, value) {
            state.miniCartItems = value
        },
        SET_CART_LOADING(state, value) {
            state.cartLoading = value
        },
        SET_MINI_CART_LOADING(state, value) {
            state.miniCartLoading = value
        },
        SET_BUY_NOW_ID(state, value) {
            state.buyNowId = value
        },  
        SET_SELECTED_CART_IDS(state, value) {
            state.selectedCartIds = value
        },        
        SET_SELECTED_CART_ITEMS(state, value) {
            state.selectedCartItems = value
        },
        REMOVE_ID_FROM_SELECTED_CART_IDS(state, id) {
            state.selectedCartIds = state.selectedCartIds.map((item) => item !== id);
        },
        REMOVE_ITEM_FROM_SELECTED_CART_ITEMS(state, id) {
            state.selectedCartItems = state.selectedCartItems.filter((obj) => obj.id !== id);
        },
        SET_IDS(state, value) {
            state.cartIds = value
            store.dispatch('cart/getMiniCartItems')
        },
        PUSH_ID(state, value) {
            if(state.cartIds.indexOf(value)==-1) {
                state.cartIds.push(value)
                store.dispatch('cart/getMiniCartItems')
                localStorage.setItem('cartIds', JSON.stringify(state.cartIds))
            }
        },
        REMOVE_ID(state, value) {
            const index = state.cartIds.indexOf(value);
            if (index != -1) {
                state.cartIds.splice(index, 1);
                store.dispatch('cart/getMiniCartItems')
                localStorage.setItem('cartIds', JSON.stringify(state.cartIds))
            }
        },
        CART_IDS_FROM_LOCAL_STORAGE(state, value){
            state.cartIds = JSON.parse(value)
            store.dispatch('cart/getMiniCartItems')
        },
        SET_SELECTED_PM(state, id) {
            state.selectedPM = id
        },
        SET_DUPLICATES(state, data)
        {
            state.duplicates = data
        }
    },
    actions: {
        setDuplicates(ctx, data) {
            ctx.commit('SET_DUPLICATES', data)
        },
        setUseBalance(ctx, value) {
            ctx.commit('SET_USE_BALANCE', value)
        },
        setSelectedPM(ctx, value) {
            ctx.commit('SET_SELECTED_PM', value)
        },
        getCartIdsFromLocalStorage(ctx, data = {}){
            let cardIdsString = localStorage.getItem('cartIds')
            if(!ctx.state.cartIds.length && cardIdsString) {
                ctx.commit('CART_IDS_FROM_LOCAL_STORAGE', cardIdsString)
            }else {
                ctx.commit('SET_MINI_CART_LOADING', false)
            }
        },
        getCartItems(ctx, data = {}){
            if(ctx.state.cartIds) {
                ctx.commit('SET_CART_LOADING', true)
                axios.get('/cart-items', {params: {ids: ctx.state.cartIds}})
                    .then(res => {
                        ctx.commit('SET_CART_ITEMS', res.data.data)
                        ctx.commit('SET_CART_LOADING', false)
                    })
                    .catch(err => {
                    })
            } else {
                ctx.commit('SET_CART_ITEMS', [])
                ctx.commit('SET_MINI_CART_LOADING', false)
            }
        },
        getMiniCartItems(ctx, data = {}){
            if(ctx.state.cartIds.length) {
                ctx.commit('SET_MINI_CART_LOADING', true)
                axios.get('/mini-cart-items', {params: {ids: ctx.state.cartIds}})
                    .then(res => {
                        ctx.commit('SET_MINI_CART_ITEMS', res.data.data)
                        ctx.commit('SET_MINI_CART_LOADING', false)
                    })
                    .catch(err => {
                    })
            } else {
                ctx.commit('SET_MINI_CART_ITEMS', [])
                ctx.commit('SET_MINI_CART_LOADING', false)
            }
        },
        setNewIdToCart(ctx, data) {
            ctx.commit('SET_MINI_CART_LOADING', true)
            ctx.commit('SET_CART_LOADING', true)
            ctx.commit('PUSH_ID', data.id)
        },
        removeIdFromCart(ctx, data) {
            ctx.commit('SET_MINI_CART_LOADING', true)
            ctx.commit('SET_CART_LOADING', true)
            ctx.commit('REMOVE_ID', data.id)
        },
        setCartItems(ctx, data) {
            ctx.commit('SET_CART_ITEMS', data)
        },
        emptyCart(ctx) {
            ctx.commit('SET_MINI_CART_LOADING', true)
            ctx.commit('SET_CART_LOADING', true)
            ctx.state.selectedCartIds.forEach(function(item){
                ctx.commit('REMOVE_ID', item)
            })
            localStorage.setItem('cartIds', JSON.stringify(ctx.state.cartIds))
            ctx.commit('SET_SELECTED_CART_IDS', [])
            ctx.commit('SET_SELECTED_CART_ITEMS', [])
        },
        setMinTransactionAmonut(ctx, data) {
            ctx.commit('SET_MIN_TRANSACTION_AMOUNT', data)
        }
    },

}