import axios from "@axios"
import router from '@/router'
import {changeUrl} from "@/plugins/helpers"

export default {
    namespaced: true,
    state: {
        order: {},
        orders: [],
        total: 0,
        loading: true,
        sales: {},
        /** Dash */
        countOrders: 0,
        countRefunds: null,
    },
    getters: {
        order(state) {
            return state.order
        },
        sales(state) {
            return state.sales
        },
        countOrders(state) {
            return state.countOrders
        },
        countRefunds(state) {
            return state.countRefunds
        },
    },
    mutations: {
        SET_ORDER(state, value) {
            state.order = value
        },
        SET_SALES(state, value) {
            state.sales = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_COUNT_ORDERS(state, value) {
            state.countOrders = value
        },
        SET_COUNT_REFUNDS(state, value) {
            state.countRefunds = value
        },
        SET_ORDERS(state, value) {
            state.orders = value
        },
        SET_TOTAL(state, value) {
            state.total = value
        }
    },
    actions: {
        getOrdersFromApi(ctx, data){
            if(changeUrl(data.url, !!data.storeId, !!data.userId) == router.currentRoute.name) {
                ctx.commit('SET_LOADING', true)
                const {search, groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data.data
                let params = {
                    pagination: ctx.rootGetters["nav/globalItemPerPage"],
                    page,
                    search,
                    tab: router.currentRoute.params.tab,
                    sort_field: sortBy && sortBy[0],
                    sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
                    user_id: data.userId,
                    store_id: data.storeId,
                }
               axios.get(data.url, {
                params
               }).then(res => {
                ctx.commit('SET_ORDERS', res.data.data)
                ctx.commit('SET_TOTAL', res.data.meta.total)
                if(ctx.rootState.pagination[router.currentRoute.name].hasOwnProperty('all')) {
                    let ob = {}
                    ob[router.currentRoute.params.tab] = {
                        itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                        page,
                        search,
                        lastPage: res.data.meta.last_page
                    }
                    ctx.commit("pagination/SET_" + router.currentRoute.name.replace(/-/gi, '_').toUpperCase() + "_PAGINATION", ob, {root: true})
                }else{
                    ctx.commit("pagination/SET_" + router.currentRoute.name.replace(/-/gi, '_').toUpperCase() + "_PAGINATION", {
                        itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                        page,
                        search,
                        lastPage: res.data.meta.last_page
                    }, {root: true})
                }
                ctx.commit('SET_LOADING', false)
               }).catch(e => console.log(e))
            }
        },
        getOrder(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get(`order/${data.id}`, {
                validateStatus: function (status) {
                    return status != 200 ? router.push({ name: 'error-404' }) : true
                }
            })
            .then(res => {
                ctx.commit('SET_ORDER', res.data.data)
                ctx.commit('SET_LOADING', false)
            })
            .catch()
        },
        getSellerOrder(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get(`seller-order/${data.id}`, {
                validateStatus: function (status) {
                    return status != 200 ? router.push({ name: 'error-404' }) : true
                }
            })
            .then(res => {
                ctx.commit('SET_ORDER', res.data.data)
                ctx.commit('SET_LOADING', false)
            })
            .catch()
        },
        getAdminOrder(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get(`admin/order/${data.id}`, {
                validateStatus: function (status) {
                    return status != 200 ? router.push({ name: 'error-404' }) : true
                }
            })
            .then(res => {
                ctx.commit('SET_ORDER', res.data.data)
                ctx.commit('SET_LOADING', false)
            })
            .catch()
        },
        getAdminOrdersDash(ctx){
            ctx.commit('SET_LOADING', true)
            axios.get('admin/dashboard/orders')
                .then(res => {
                    ctx.commit('SET_COUNT_ORDERS', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getSellerOrdersDash(ctx){
            ctx.commit('SET_LOADING', true)
            axios.get('dashboard/orders')
                .then(res => {
                    ctx.commit('SET_COUNT_ORDERS', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getAdminRefundsDash(ctx){
            ctx.commit('SET_LOADING', true)
            axios.get('admin/dashboard/refunds')
                .then(res => {
                    ctx.commit('SET_COUNT_REFUNDS', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getSellerRefundsDash(ctx){
            ctx.commit('SET_LOADING', true)
            axios.get('dashboard/refunds')
                .then(res => {
                    ctx.commit('SET_COUNT_REFUNDS', { all: res.data })
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getAdminSalesDash(ctx){
            ctx.commit('SET_LOADING', true)
            axios.get('admin/dashboard/sales')
                .then(res => {
                    ctx.commit('SET_SALES', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getSellerSalesDash(ctx){
            ctx.commit('SET_LOADING', true)
            return axios.get('dashboard/sales')
                .then(res => {
                    ctx.commit('SET_SALES', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
    },

}
