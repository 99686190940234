import axios from "@axios"
import router from '@/router'
import { mergeDeep } from "@/plugins/helpers"
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        toggleFilter: false,
        updateFilter: null,
        loading: true,
        defaultData: {
            'store_ratings': '1,2,3,4',
            'supplier': null,
            'status': 0,
            'category': 'All'
        },
        rating: {
            gold: true,
            silver: true,
            bronze: true,
            wooden: true,
        },
        queryParams: {},
        filterData: {},
        savedFilterData: {},
        constFilter: {},
        defaultMarketplace: 'amazon.com'
    },
    getters: {
        toggleFilter(state) {
            return state.toggleFilter
        },
        queryParams(state) {
            return state.queryParams
        },
        updateFilter(state) {
            return state.updateFilter
        },
        loading(state) {
            return state.loading
        },
        filterData(state) {
            Vue.delete(state.filterData, 'amz_categories')
            Vue.delete(state.filterData, 'amz_marketplaces')
            return state.filterData
        },
        savedFilterData(state) {
            return state.savedFilterData
        },
        constFilter(state) {
            return state.constFilter
        },
        getFilterDataString(state) {
            return JSON.stringify(state.savedFilterData)
        }
    },
    mutations: {
        SET_TOGGLE_FILTER(state, value) {
            state.toggleFilter = value
        },
        SET_QUERY_PARAMS(state, value) {
            state.queryParams = {...state.queryParams, ...value}
        },
        CLEAR_QUERY_PARAMS(state) {
            state.queryParams = {}
        },
        QUERY_DEL_SEARCH(state) {
            Vue.delete(state.queryParams, 'search')
        },
        UPDATE_FILTER(state) {
            state.updateFilter++
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_CONST_FILTER(state, value) {
            state.constFilter = value
        },
        SET_FILTER_DATA(state, value) {
            state.filterData = {...state.filterData, ...value}
        },
        SET_SAVED_FILTER_FROM_URL(state, value) {
            state.savedFilterData = {...state.savedFilterData, ...value}
            state.filterData = {...state.filterData, ...value}
        },
        SET_SKELETON_FILTER_DATA(state, value) {
            let obj = {}
            for(var item in value) {
                obj[item] = item !== 'version' && item !== 'marketplace' && item !== 'category' ? null : value[item]
            }
            state.filterData = {...obj, ...state.savedFilterData, ...{
                'status': state.savedFilterData?.status ? parseInt(state.savedFilterData.status) : 0
            }}
        },
        SET_SKELETON_FILTER_DATA_FROM_CONST(state) {
            let obj = {}
            for(var item in state.constFilter) {
                obj[item] = item !== 'version' && item !== 'marketplace' ? null : state.constFilter[item]
            }
            state.filterData = JSON.stringify(state.savedFilterData) !== '{}' ? {...obj, ...state.defaultData, ...state.savedFilterData} : {...obj, ...state.defaultData}
        },
        CLEAR_SAVED_FILTER_DATA(state) {
            state.savedFilterData = state.savedFilterData?.hasOwnProperty('marketplace') ? {...{}, ...{'marketplace': state.savedFilterData.marketplace}} : {...{}}
            router.replace({name: router.currentRoute.name, query: state.queryParams, params: { savePosition: true }}).catch(()=>{})
        },
        SET_SAVED_FILTER_DATA(state, value) {
            state.savedFilterData = mergeDeep({version: state.constFilter.version, marketplace: state.constFilter.marketplace, store_ratings: state.constFilter.store_ratings}, state.savedFilterData, value)
        }
    },
    actions: {
        async getFilterData(ctx) {
            ctx.commit('SET_LOADING', true)
            let url = 'products-filter'
            if(ctx.state.savedFilterData?.marketplace) url += '/' + ctx.state.savedFilterData.marketplace
            return axios.get(url).then(res => {
                ctx.commit('SET_SKELETON_FILTER_DATA', res.data.data)
                /** constFilter needed for comparisons, fresh if reload */
                ctx.commit('SET_CONST_FILTER', res.data.data)
                // if(ctx.state.savedFilterData.version != res.data.data.version) {
                    /** Clear savedFilterData if users don't have version or versions don't match */
                    // ctx.commit('CLEAR_SAVED_FILTER_DATA')
                // }
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        }
    },
}
