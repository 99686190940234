import store from "@/store"
import router from '@/router'

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Convert currency code to symbol.
 */
 export function cCodeToSymbol() {
  return {
    'USD': '$',
    'CAD': 'CDN$',
    'MXN': '$',
    'BRL': 'R$',
    'AED': 'AED',
    'EUR': '€',
    'LE': '£',
    'GBP': '£',
    'INR': '₹',
    'PLN': 'zł',
    'SAR': 'SAR',
    'SEK': 'kr',
    'TRY': '₺',
    'SGD': '$',
    'AUD': '$',
    'JPY': '￥',
  }
}

/**
 * Marketplaces corrency codes
 */
 export function marketCurrCode() {
  return {
    'amazon.com': 'USD',
    'amazon.ca': 'CAD',
    'amazon.com.mx': 'MXN',
    'amazon.com.br': 'BRL',
    'amazon.ae': 'AED',
    'amazon.de': 'EUR',
    'amazon.eg': 'LE',
    'amazon.es': 'EUR',
    'amazon.fr': 'EUR',
    'amazon.co.uk': 'GBP',
    'amazon.in': 'INR',
    'amazon.it': 'EUR',
    'amazon.nl': 'EUR',
    'amazon.pl': 'PLN',
    'amazon.sa': 'SAR',
    'amazon.se': 'SEK',
    'amazon.com.tr': 'TRY',
    'amazon.sg': 'SGD',
    'amazon.com.au': 'AUD',
    'amazon.co.jp': 'JPY'
  }
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object')
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export function numberWithSpaces(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").split(".")[0] : 0
}

export function uFirstLetter(string) {
  if(typeof string === 'string') {
      return string.charAt(0) ? string.charAt(0).toUpperCase() + string.slice(1) : string;
  }
  return string
}

export function kitCut(text, limit) {
  if(!text) return ''
  text = text.trim()
  if(text.length <= limit) return text
  text = text.slice( 0, limit)
  let lastSpace = text.lastIndexOf(" ")
  if(lastSpace > 0) text = text.substr(0, lastSpace)
  return text + "..."
}

export function trimString(string, length) {
  return string?.length > length ? 
          string.substring(0, length) + '...' :
          string;
}

export function truncate(str, n, useWordBoundary) {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString);
}

export function changeUrl(url, store = false, user = false) {
    switch (url) {
      case "admin/orders":
          if(store)
            return 'admin-orders-store-list'
          if(user)
            return 'admin-orders-user-list'
          return 'admin-orders-list'
        break
      case "admin/refunds":
          if(store)
            return 'admin-refunds-store-list'
          if(user)
            return 'admin-refunds-user-list'
          return 'admin-refunds-list'
        break
      default:
        return url
    }
}

export function isHaveTabs() {
  return store.state.pagination[router.currentRoute.name].hasOwnProperty('all')
}

export function isBuyer(routeName) {
  return !(routeName.includes('seller') || routeName.includes('admin'))
}

export function relativeLink(routeName) {
  if(routeName.includes('seller'))
    return 'seller-order'
  if(routeName.includes('admin'))
    return 'admin-order'
  return 'order'
}

export function getUserHeadersByTabName(tabName) {
  let userHeaders = [
    { text: 'ID', value: 'id', width: 60, sortable: true },
    { text: 'User', value: 'name', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Role', value: 'roles', sortable: false },
    { text: 'Status', value: 'status', width: 115, sortable: false, align: 'center' },
    { value: 'eye', sortable: false, align: 'end'}
  ]
  let userStoreHeaders = [
    { text: 'ID', value: 'id', width: 60, sortable: true },
    { text: 'User', value: 'name', sortable: false },
    { text: 'Store', value: 'store_title', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Role', value: 'roles', sortable: false },
    { text: 'Status', value: 'status', width: 115, sortable: false, align: 'center' },
    { value: 'eye', sortable: false, align: 'end'}
  ]
  if(tabName == 'seller' || tabName == 'moderator') {
    return userStoreHeaders
  }
  return userHeaders
}

export function getOrderRefHeadersByRouteName(routeName) {
    let refundHeaders = [
      {text: 'Dispute ID', value: 'dispute_id', sortable: false, width: 100},
      {text: 'Dispute Date', value: 'dispute_date', sortable: false, width: 120},
      {text: 'Order ID', value: 'id', sortable: false, width: 100},
      {text: 'Product ID', value: 'product_id', width: 115},
      {text: 'Store', value: 'store', sortable: false},
      {text: 'Deal Price', value: 'price', width: 110 },
      {text: 'Status', value: 'status', width: 100, align: 'center'},
      {value: 'eye', sortable: false, align: 'end'}
    ]
    let headers = [
      {text: 'Order ID', value: 'id', sortable: false, width: 100},
      {text: 'Date', value: 'created_at', width: 115},
      {text: 'Keepa', value: 'keepa_image', sortable: false, width: 90},
      {text: 'Product', value: 'image', sortable: false, width: 90},
      {text: 'Product ID', value: 'product_id', width: 115},
      {text: 'ASIN<br>Source', value: 'source_link',  sortable: false},
      {text: 'BuyBox price<br>COG&nbsp&nbsp3PL', value: 'buy_box_price', width: 130},
      {text: 'Store', value: 'store', sortable: false, width: 130},
      {text: 'Status', value: 'status', sortable: false, align: 'center'},
      {text: 'Deal Price', value: 'price', width: 110 },
      {value: 'eye', sortable: false, align: 'end'}
    ]
    let sellerHeaders = [
      {text: 'Order ID', value: 'id', sortable: false, width: 100},
      {text: 'Date', value: 'created_at', width: 115},
      {text: 'Keepa', value: 'keepa_image', sortable: false, width: 90},
      {text: 'Product', value: 'image', sortable: false, width: 90},
      {text: 'Product ID', value: 'product_id', width: 115},
      {text: 'ASIN<br>Source', value: 'source_link',  sortable: false},
      {text: 'BuyBox price<br>COG&nbsp&nbsp3PL', value: 'buy_box_price', width: 130},
      {text: 'Status', value: 'status', sortable: false, align: 'center'},
      {text: 'Deal Price', value: 'price', width: 110 },
      {value: 'eye', sortable: false, align: 'end'}
    ]
    let adminHeaders = [
      {text: 'Order ID', value: 'id', sortable: false, width: 100},
      {text: 'Date', value: 'created_at', width: 100},
      {text: 'Product', value: 'image', sortable: false, width: 90},
      {text: 'Product ID', value: 'product_id', width: 115},
      {text: 'ASIN<br>Source', value: 'source_link',  sortable: false},
      {text: 'Buyer', value: 'buyer', sortable: false},
      {text: 'Seller', value: 'seller', sortable: false},
      {text: 'Status', value: 'status', sortable: false, align: 'center'},
      {text: 'Deal Price', value: 'price', width: 110 },
      {value: 'eye', sortable: false, align: 'end'}
    ]
    let refundsRoutes = [
      'refunds-list',
      'seller-refunds-list',
      'admin-refunds-list',
      'admin-refunds-user-list',
      'admin-refunds-store-list',
    ]
    let adminOrdersRoutes = [
      'admin-orders-list',
      'admin-orders-store-list',
    ]
    return refundsRoutes.includes(routeName) ? refundHeaders : (adminOrdersRoutes.includes(routeName) ? adminHeaders : (routeName.includes('seller') ? sellerHeaders : headers))
}

export function getRejectVariants() {
  return [
    {id: 1, title: 'Wrong Amazon link', description: 'Please add a non-referral link to Amazon.'},
    {id: 2, title: 'Wrong Supplier Link', description: 'The supplier\'s link does not lead to the product page.'},
    {id: 3, title: 'Unreliable website', description: 'The supplier\'s website looks unreliable.'},
    {id: 4, title: 'Out of Stock', description: 'The item is out of stock at the supplier.'},
    {id: 5, title: 'Sold as Noname', description: 'The branded product is sold on Amazon as unbranded.'},
    {id: 6, title: 'Wrong Brand', description: 'The supplier\'s brand doesn\'t match the brand on Amazon.'},
    {id: 7, title: 'Wrong Product', description: 'The product on Amazon doesn\'t match the supplier\'s product.'},
    {id: 8, title: 'Wrong Quantity', description: 'The product count on Amazon doesn\'t match the supplier\'s product count.'},
    {id: 9, title: 'Wrong COG', description: 'Incorrect cost of goods.'},
    {id: 10, title: 'Wrong 3PL', description: 'Please specify the supplier shipping cost. If the delivery is free, this must be indicated in the Description.'},
    {id: 11, title: 'Low sales', description: 'Too low sales.'},
    {id: 12, title: 'Purchase limit', description: 'Please, specify the purchasing limit in the description.'},
    {id: 13, title: 'Unprofitable deal', description: 'Unprofitable deal.'},
    {id: 14, title: 'Unable to verify', description: 'Sorry, we are unable to verify this deal. We need to register an account and enter business details to see prices.'},
    {id: 15, title: 'Not in English', description: 'Please use English in the description and the message for buyer fields.'},
    {id: 16, title: 'Other reason', description: ''}
  ]
}