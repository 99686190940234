<template>
  <v-app
      class="content-layout"
  >
    <v-app-bar
        v-if="appBarType !== 'hidden'"
        ref="refAppBar"
        app
        :absolute="appBarType === 'static'"
        flat
        :elevate-on-scroll="appBarType !== 'static'"
        :elevation="appBarType !== 'static' ? $vuetify.theme.isDark ? 4: 3 : 0"
        class="mx-auto app-bar-static"
        :style="appBarStyle"
        style="transition: all 0.2s ease-in-out;"
        :class="[{ 'px-5 app-bar-shinked': appBarType === 'fixed' && scrollY }, { 'bg-blur': appBarIsBlurred && appBarType === 'fixed' && scrollY }]"
    >
      <router-link
          to="/"
          class="d-flex align-center text-decoration-none logo-link"
      >
        <v-img
            :src="icons.appLogo"
            max-height="50px"
            max-width="50px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
        ></v-img>
<!--        <v-slide-x-transition>-->
<!--          <div class="text&#45;&#45;primary">-->
<!--            <div style="display: grid">-->
<!--              <span class="app-title h2">{{ appName }}</span>-->
<!--              <span class="deals-sub-title">Seller Assistant</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </v-slide-x-transition>-->
      </router-link>
      <div class="d-flex flex-fill"></div>
      <div class="navbar-content-container">
        <div class="d-flex align-center right-row">
          <app-bar-i18n></app-bar-i18n>
          <div class="ml-3 px-1 adaptive-login">
            <v-icon>{{ icons.mdiAccount }}</v-icon>
            <v-btn text color="primary" min-width="0" class="text-capitalize pa-1"
                   @click="$router.push({name: 'auth-login'})">
              {{ $t('login') }}
            </v-btn>
            <span>/</span>
            <v-btn text color="primary" min-width="0" class="text-capitalize pa-1"
                   @click="$router.push({name: 'auth-register'})">
              {{ $t('register') }}
            </v-btn>
          </div>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <mini-cart></mini-cart>
        </div>
      </div>
    </v-app-bar>
    <slot name="v-app-content"></slot>

    <v-main class="unsigned-container">
      <app-content-container>
        <slot></slot>
      </app-content-container>
    </v-main>
    <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="4"
        absolute
        class="content-overlay"
    ></v-overlay>
  </v-app>
</template>

<script>
import AppContentContainer from '@core/layouts/components/app-content-container/AppContentContainer.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import MiniCart from '@/views/market/cart/MiniCart'
import useAppConfig from "@core/@app-config/useAppConfig";
import {useWindowScroll, useWindowSize} from "@vueuse/core";
import {computed} from "@vue/composition-api";
import {mdiAccount} from "@mdi/js";
import themeConfig from "@themeConfig";

export default {
  name: "LayoutUnsigned",
  components: {
    AppContentContainer,
    AppBarI18n,
    AppBarThemeSwitcher,
    MiniCart,
  },
  setup() {
    const {appBarType, appBarIsBlurred} = useAppConfig()
    const {y: scrollY} = useWindowScroll()
    const {width} = useWindowSize()
    const appBarStyle = computed(() => {
      if (appBarType.value === 'fixed' && scrollY.value && width.value <= 1440) {
        return {
          'margin-left': '2% !important',
          'margin-right': '2% !important',
          'border-radius': '5px',
        }
      } else if (appBarType.value === 'fixed' && scrollY.value && width.value > 1440) {
        return {
          'max-width': '1440px',
          'border-radius': '5px',
        }
      } else {
        return {}
      }
    })
    return {
      appBarType,
      appBarIsBlurred,
      scrollY,
      appBarStyle,
      icons: {
        mdiAccount,
        appLogo: themeConfig.app.logo,
      },
      appName: themeConfig.app.name,
    }
  }
}
</script>
<style lang="scss" scoped>
.unsigned-container {
  margin: 2% !important;
}
@media only screen and (max-width: 375px) {
  .adaptive-login {
    display: contents !important;
  }
}
.logo-link {
  position: sticky;
  z-index: 3;

  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .beta-block {
    position: absolute;
    right: -20px;
    bottom: -10px;
    padding: 8px;
    font-weight: bold;
  }
}
</style>
