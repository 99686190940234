import axios from "@axios"
import router from '@/router'

export default {
    namespaced: true,
    state: {
        users: [],
        total: 0,
        loading: true,
        //UsersInfo component
        usersShortInfo: {
            all: { title: 'All', count: 0 },
            customers: { title: 'Buyers', count: 0 },
            sellers: { title: 'Sellers', count: 0 },
            moderatorsAndAdmins: { title: 'Moderators/Admins', count: 0 },
        },
        userHash: '',
        userResource: {}
    },
    getters: {
        users(state) {
            return state.users
        },
        total(state) {
            return state.total
        },
        usersShortInfo(state) {
            return state.usersShortInfo
        },
        userResource(state) {
            return state.userResource
        },
        userHash(state) {
            return state.userHash
        }
    },
    mutations: {
        SET_USERS(state, value) {
            state.users = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_TOTAL(state, value) {
            state.total = value
        },
        SET_SHORT_USERS_INFO(state, value) {
            state.usersShortInfo.all.count = value.all || 0
            state.usersShortInfo.customers.count = value.customers || 0
            state.usersShortInfo.sellers.count = value.sellers || 0
            state.usersShortInfo.moderatorsAndAdmins.count = value.moderators_and_admins || 0
        },
        SET_USER_RESOURCE(state, { vm, data }) {
            const Role = state.userResource.role ? JSON.parse(JSON.stringify(state.userResource.role)) : null
            if(Role !== null && Role !== data.role) {
                vm.$store.dispatch('user/authMe', { vm })
            }
            state.userResource = data
        },
        SET_USER_HASH(state, value) {
            state.userHash = value
        }
    },
    actions: {
        authMe(ctx, { vm }) {
            axios.get('/auth/me').then(response => {
                const user = response.data.data
                const { ability: userAbility } = user
                vm.$ability.update(userAbility)
                localStorage.setItem('userAbility', JSON.stringify(userAbility))
            })
        },
        getUsers(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const {search, groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            let params = {
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                role_tab: router.currentRoute.params.tab,
                search,
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortBy && sortDesc[0] ? 'ASC' : 'DESC'
            }
            axios.get('admin/users', {
                params
            }).then(res => {
                ctx.commit('SET_USERS', res.data.data)
                ctx.commit('SET_TOTAL', res.data.meta.total)
                let ob = {}
                ob[params.role_tab] = {
                    itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                    search,
                    page,
                    lastPage: res.data.meta.last_page
                }
                ctx.commit("pagination/SET_USERS_PAGINATION", ob, {root: true})
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        },
        getUsersShortInfo(ctx){
            ctx.commit('SET_LOADING', true)
            axios.get('admin/users-info')
                .then(res => {
                    ctx.commit('SET_SHORT_USERS_INFO', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        async getUserResource(ctx, { vm }) {
            await axios.get('user-resource').then(res => {
                let data = res.data.data
                ctx.commit('SET_USER_HASH', data.user_hash)
                delete data.user_hash
                ctx.commit('SET_USER_RESOURCE', { vm, data })
            }).catch()
        }
    },

}
