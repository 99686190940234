import Echo from "laravel-echo"
import appConfig from "@appConfig";

window.Pusher = require('pusher-js');
// window.Pusher.logToConsole = true;

const echo = new Echo({
    broadcaster: 'pusher',
    key: appConfig.websocketsKey,
    wsHost: appConfig.websocketsServer,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    encrypted: true,
    authEndpoint: appConfig.broadcastingAuthUrl,
    auth: {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        }
    }
});

export default echo

