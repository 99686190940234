<template>
  <v-row>
    <v-col class="pr-0 ml-3">
      <top-up>
        <template v-slot:default="slotProps">
          <v-btn
              v-bind="slotProps.attrs"
              v-on="slotProps.on"
              block
              color="warning" x-small
              class="black--text font-weight-bold"
              @click="topUpDialogOpen"
          >
            {{ $t('TOP UP') }}
          </v-btn>
        </template>
      </top-up>
    </v-col>
    <v-col class="saa-buyers-balance">
      <v-slide-x-reverse-transition>
      <div v-show="!balanceLoading" class="font-weight-semibold info--text" style="line-height: 0.9rem">
        ${{ balance }}
        <span class="d-block buyers-balance">{{ $t('buyer\'s balance') }}</span>
      </div>
      </v-slide-x-reverse-transition>
    </v-col>
  </v-row>
</template>

<script>
import {computed, onBeforeMount} from "@vue/composition-api";
import store from "@/store";
import TopUp from "@/views/components/payment/TopUp";
import {topUpDialogOpened} from "@/plugins/tracking/events";
import {logEventAmplitude} from "@/plugins/tracking/amplitude";
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";
export default {
  name: "UserBalance",
  components: {
    TopUp
  },
  setup() {
    onBeforeMount(() => store.dispatch('account/getBalance'))
    const balance = computed(() => store.state.account.balance)
    const balanceLoading = computed(() => store.state.account.balanceLoading)
    const topUpDialogOpen = () => {
      logEventAmplitude(topUpDialogOpened.name)
      logEventMixPanel(topUpDialogOpened.name)
    }
    return {
      balance,
      balanceLoading,
      topUpDialogOpen
    }
  }
}
</script>
