<template>
  <v-row>
      <v-col class="ml-3">
        <v-slide-x-reverse-transition>
        <div v-show="!balanceLoading" class="font-weight-semibold info--text" style="line-height: 0.9rem">
          ${{ Number(balance) }}
          <span class="d-block buyers-balance">{{ $t('store balance') }}</span>
        </div>
        </v-slide-x-reverse-transition>
      </v-col>
  </v-row>
</template>

<script>
import {computed, onBeforeMount} from "@vue/composition-api";
import store from "@/store";
import TopUp from "@/views/components/payment/TopUp";
export default {
  name: "StoreBalance",
  components: {
    TopUp
  },
  setup() {
    onBeforeMount(() => store.dispatch('market/getStoreBalance'))
    const balance = computed(() => store.state.market.balance)
    const balanceLoading = computed(() => store.state.market.balanceLoading)
    return {
      balance,
      balanceLoading,
    }
  }
}
</script>
