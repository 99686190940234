import {mdiStore} from "@mdi/js";

export default [
    {
        title: 'Store',
        key: 'marketplace',
        to: 'products',
        icon: mdiStore,
        action: 'read'
    }
]