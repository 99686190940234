<template>
  <div class="text-center">
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="300"
        offset-x
        offset-y
        :elevation="$vuetify.theme.dark ? 9 : 8"
        :min-width="300"
        :max-width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
            :content="CartProducts.length"
            :value="CartProducts.length"
            color="success"
            class="mr-4"
            offset-x="8"
            offset-y="8"
        >
          <v-icon
              v-bind="attrs"
              v-on="on"
              @click="miniCardOpened"
          >
            {{ icons.mdiCartVariant }}
          </v-icon>
        </v-badge>
      </template>

      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="pb-1 d-block">
          {{ $t('Shopping cart') }}
        </v-card-title>
        <v-list v-show="CartProducts.length" class="mini-cart-list">
          <v-list-item v-for="product in CartProducts" :key="product.id" class="py-9 my-1">
            <div class="pr-2">
              <router-link :to="{name: 'product', params: {id: product.id}}" class="d-flex justify-center">
                <v-img
                  width="60"
                  height="40"
                  style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;border-radius: 5px"
                  :src="product.keepa_image ? product.keepa_image : noKeepaImage"
                />
              </router-link>
            </div>
            <v-list-item-content style="font-size: 13px">
              <div class="d-inline-flex mb-1">
                <div class="d-flex align-center">
                  <v-chip
                      v-if="product.bsr_avg30" small label
                      style="min-width: 40px; justify-content: center; font-size: 13px"
                      :color="product.bsr_avg30 > 0? 'success':'error'"
                      :class="product.bsr_avg30 > 0? 'success--text':'error--text'"
                      class="v-chip-light-bg">
                    {{ product.bsr_avg30 | numberWithSpaces }}
                  </v-chip>
                </div>
                <div class="flex-grow-1 d-flex align-center justify-center">{{ product.category }}</div>
              </div>
              <div class="d-flex justify-space-between align-center">
                <div class="text-left">
                  <div>
                    <b>{{ $t('Profit') }}: </b>
                    <span v-if="product.profit_fba">
                      {{ cCodeToSymbol[product.currency_code] }}{{ Number(product.profit_fba) }}
                    </span>
                  </div>
                  <div><b>{{ $t('ROI') }}:</b> <span v-if="product.roi_fba" style="color: #56CA00">{{ product.roi_fba }}%</span></div>
                </div>
                <div/>
                <div>
                  <div class="font-weight-bold">{{ $t('Deal Price') }}</div>
                  <div class="font-weight-bold" style="font-size: 15px">{{ '$' + product.price }}</div>
                </div>
              </div>
            </v-list-item-content>
            <div class="pl-3">
              <v-list-item-action>
                <v-btn icon small @click="removeFromList(product.id)">
                  <v-icon size="20">{{ icons.mdiTrashCanOutline }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </div>
          </v-list-item>
        </v-list>
        <v-card-actions
            v-show="!loading && CartProducts.length">
          <div style="width: 100%; font-size: 17px">
            <div style="width: 100%;" v-if="priceSum > 0" class="d-flex justify-space-between mb-4 font-weight-bold">
              <span><h4>{{ $t('Sub-total:') }}</h4></span>
              <span class="float-right"><h4>${{ priceSum }}</h4></span>
            </div>
          <v-btn
              v-if="!unsigned"
              color="primary"
              @click="goToCheckout"
              class="mb-1"
              block
          >
            {{ $t('Checkout') }}
          </v-btn>
            <v-btn
                v-else
                color="primary"
                @click="goToLogin()"
                class="mb-1"
                block
            >
              {{ $t('Checkout') }}
            </v-btn>
          </div>
        </v-card-actions>
        <v-card-text v-show="!loading && !CartProducts.length">
          <div class="text-center col-12 deals-cart-empty">
            <v-icon size="100" color="grey-400"> {{ icons.mdiCart }}</v-icon></br>
            <span class="font-weight-bold grey-400--text">{{ $t('Cart is empty') }}</span>
          </div>
          <v-btn
              color="primary"
              @click="goToMarketplace"
              small
              block
          >
            {{ $t('Go to marketplace') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "@vue/composition-api";
import {mdiCart, mdiCartVariant, mdiTrashCanOutline} from "@mdi/js";
import { numberWithSpaces, cCodeToSymbol } from "@/plugins/helpers"
import store from "@/store";
import {useRouter} from "@core/utils";
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";
import {logEventAmplitude} from "@/plugins/tracking/amplitude";
import {
  cardGoToCheckout,
  cardGoToCheckoutUnsigned,
  miniCardOpenClicked,
  removeProductFromMiniCart
} from "@/plugins/tracking/events";

export default {
  name: "MiniCart",
  filters: {
    numberWithSpaces,
  },
  setup() {
    const fav = ref(true)
    const menu = ref(false)
    const priceSum = ref(0)
    const {router, route} = useRouter()
    const removeFromList = (id) => {
      let event = removeProductFromMiniCart(id)
      logEventAmplitude(event.name, event.props)
      logEventMixPanel(event.name, event.props)
      store.dispatch('cart/removeIdFromCart', {id})
    }
    const goToMarketplace = () => {
      menu.value = false
      if(route.value.name != 'products') router.push({name: 'products'})
    }
    const goToCheckout = () => {
      logEventAmplitude(cardGoToCheckout.name)
      logEventMixPanel(cardGoToCheckout.name)
      menu.value = false
      if(route.value.name != 'checkout') router.push({name: 'checkout'})
    }
    const loading = computed(() => store.state.cart.miniCartLoading)
    const productsIdsInCart = computed(() => store.state.cart.cartIds)
    const CartProducts = computed({
      get: () => {
        priceSum.value = store.state.cart.miniCartItems.reduce(
            (previousValue, currentValue) => {
              return previousValue + currentValue.price
            },
            0
        ).toFixed(2);
        return store.state.cart.miniCartItems
      }
    })
    const unsigned = computed(() => route.value.meta.user === 'unsigned')
    const goToLogin = () => {
      logEventAmplitude(cardGoToCheckoutUnsigned.name, cardGoToCheckoutUnsigned.props)
      logEventMixPanel(cardGoToCheckoutUnsigned.name, cardGoToCheckoutUnsigned.props)
      router.push({name: 'auth-login', query: {route: 'checkout'}})
    }
    const miniCardOpened = () => {
      logEventAmplitude(miniCardOpenClicked.name)
      logEventMixPanel(miniCardOpenClicked.name)
    }
    store.dispatch('cart/getCartIdsFromLocalStorage')
    return {
      fav,
      menu,
      productsIdsInCart,
      CartProducts,
      priceSum,
      loading,
      unsigned,
      goToCheckout,
      goToLogin,
      removeFromList,
      goToMarketplace,
      miniCardOpened,
      cCodeToSymbol: cCodeToSymbol(),
      icons: {
        mdiCartVariant,
        mdiTrashCanOutline,
        mdiCart,
      },
      noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
    }
  }
}
</script>
<style lang="scss" scoped>
.mini-cart-list{
  font-size: 12px;
  .v-list-item{
    padding: 0 10px;
    &__action {
      margin: 0;
    }
    background: rgba(211, 211, 211, 0.2);
  }
}
</style>