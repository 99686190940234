import store from "@/store";

export const initIntercom = (intercom) => {
    if(intercom) {
        if (intercom.isBooted)
            intercom.shutdown();
        const userResource = store.state.user.userResource
        const userHash = store.state.user.userHash
        intercom.boot(
            Object.assign(userResource, {user_id: userResource?.id, user_hash: userHash})
        )
    }
}
