import {mdiAccountTie, mdiClipboardCheck, mdiFileMultiple} from "@mdi/js";

export default [
    {
        subheader: 'Buyer',
        action: 'read'
    },
    {
        title: 'Orders',
        icon: mdiClipboardCheck,
        key: 'buyer-orders',
        action: 'read',
        children: [
            {
                title: 'All Orders',
                to: 'orders-list',
                action: 'read',
            },
            {
                title: 'Refunds',
                action: 'read',
                to: 'refunds-list'
            }
        ]
    },
    {
        title: 'Transactions',
        key: 'buyer-transactions',
        to: 'transactions',
        icon: mdiFileMultiple,
        action: 'read',
    },
    {
        title: 'Account',
        icon: mdiAccountTie,
        action: 'read',
        to: 'user-account',
        children: [
            {
                title: 'Profile',
                action: 'read',
                to: { name: 'user-account', params: { tab: 'profile' } },
            },
            {
                title: 'Amazon Account',
                action: 'read',
                to: { name: 'user-account', params: { tab: 'amazon-account' } },
            },
            {
                title: 'Billing',
                action: 'read',
                to: { name: 'user-account', params: { tab: 'billing' } },
            },
            {
                title: 'Security',
                action: 'read',
                to: { name: 'user-account', params: { tab: 'security' } },
            }
        ]
    }
]