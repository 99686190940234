<template>
  <layout-content-unsigned>
    <slot></slot>
  </layout-content-unsigned>
</template>

<script>
import LayoutContentUnsigned from "@core/layouts/variants/unsigned/LayoutUnsigned";

export default {
  name: "LayoutUnsigned",
  components: { LayoutContentUnsigned }
}
</script>
