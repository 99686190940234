import axios from "@axios";
import router from '@/router'

export default {
    namespaced: true,
    state: {
        product: {},
        products: [],
        total: 0,
        loading: true,
        //ProductsInfo component
        productsShortInfo: {
            all: { title: 'All', count: 0, color: '#9155FD' },
            active: { title: 'Active', count: 0, color: '#56CA00' },
            inactive: { title: 'Inactive', count: 0, color: '#FFB400' },
            draft: { title: 'Draft', count: 0, color: '#8A8D93' },
            banned: { title: 'Rejected / Banned', count: 0, color: '#FF4C51' },
        },
        restrictions: [],
        keepaGraphScale: 90
    },
    getters: {
        product(state) {
            return state.product
        },
        loading(state) {
            return state.loading
        },
        products(state) {
            return state.products
        },
        total(state) {
            return state.total
        },
        productShortInfo(state) {
            return state.productsShortInfo
        },
        restrictions(state) {
            return state.restrictions
        },
        keepaGraphScale(state) {
            return state.keepaGraphScale
        }
    },
    mutations: {
        SET_PRODUCT(state, value) {
            state.product = value
        },
        SET_KEEPA_GRAPH_SCALE(state, value) {
            state.keepaGraphScale = value
        },
        SET_PRODUCTS(state, value) {
            state.products = value
        },
        SET_TOTAL(state, value) {
            state.total = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_SHORT_PRODUCTS_INFO(state, value) {
            state.productsShortInfo.all.count = value.all || 0
            state.productsShortInfo.active.count = value.active || 0
            state.productsShortInfo.inactive.count = value.inactive || 0
            state.productsShortInfo.draft.count = value.draft || 0
            state.productsShortInfo.banned.count = value.banned || 0
        },
        SET_RESTRICTION(state, value) {
            state.restrictions.push(value)
        }
    },
    actions: {
        getProductsForApprove(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            axios.get('admin/products-for-approve', {
              params: {
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
                status: 1
              }
            }).then(res => {
                ctx.commit('SET_PRODUCTS', res.data.data.map(e => Object.assign(e, {access: ''})))
                ctx.commit('SET_TOTAL', res.data.meta.total)
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        },
        getProductsForApproveV2(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            axios.get('admin/products-for-approve-v2', {
              params: {
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
                status: 1
              }
            }).then(res => {
                ctx.commit('SET_PRODUCTS', res.data.data.map(e => Object.assign(e, {access: ''})))
                ctx.commit('SET_TOTAL', res.data.meta.total)
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        },
        getAdminProducts(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const {marketId, search, groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            let params = {
                search,
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                market_id: marketId,
                status_tab: router.currentRoute.params.tab,
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
            }
            axios.get('admin/admin-products', {
                params
            }).then(res => {
                ctx.commit('SET_PRODUCTS', res.data.data.map(e => Object.assign(e, {access: ''})))
                ctx.commit('SET_TOTAL', res.data.meta.total)
                let ob = {}
                ob[params.status_tab] = {
                    itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                    search,
                    page,
                    lastPage: res.data.meta.last_page
                }
                ctx.commit("pagination/SET_ADMIN_PRODUCTS_PAGINATION", ob, {root: true})
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        },
        getSellerProducts(ctx, data) {
            ctx.commit('SET_LOADING', true)
            const {search, groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            let params = {
                search,
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                status_tab: router.currentRoute.params.tab,
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'ASC' : 'DESC',
            }
            axios.get('seller-products', {
                params
            }).then(res => {
                ctx.commit('SET_PRODUCTS', res.data.data.map(e => Object.assign(e, {access: ''})))
                ctx.commit('SET_TOTAL', res.data.meta.total)
                let ob = {}
                ob[params.status_tab] = {
                    itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                    search,
                    page,
                    lastPage: res.data.meta.last_page
                }
                ctx.commit("pagination/SET_PRODUCTS_PAGINATION", ob, {root: true})
                ctx.commit('SET_LOADING', false)
            }).catch(e => console.log(e))
        },
        getSellerProduct(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get(`seller-product/${data.id}`)
                .then(res => {
                    ctx.commit('SET_PRODUCT', res.data.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getAdminProduct(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get(`admin/product/${data.id}`)
                .then(res => {
                    ctx.commit('SET_PRODUCT', res.data.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getProduct(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get(`product/${data.id}`, {
                    validateStatus: function (status) {
                        return status != 200 ? router.push({ name: 'error-404' }) : true
                    }
                })
                .then(res => {
                    ctx.commit('SET_PRODUCT', res.data.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getProductsShortInfo(ctx, data){
            ctx.commit('SET_LOADING', true)
            axios.get('seller-products-info')
                .then(res => {
                    ctx.commit('SET_SHORT_PRODUCTS_INFO', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getAdminProductsShortInfo(ctx, data){
            ctx.commit('SET_LOADING', true)
            const URL = data?.store_id ? 'admin/products-info/'+ data.store_id : 'admin/products-info'
            axios.get(URL)
                .then(res => {
                    ctx.commit('SET_SHORT_PRODUCTS_INFO', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        addRestriction(ctx, data) {
            ctx.commit('SET_RESTRICTION', data)
        },
        updateProductStatus(ctx, data) {
            axios.post(`update-product-status/${data.id}`, {status: data.status == 0 ? 1 : 0, _method: 'put'})
                .then(res => {
                    ctx.dispatch(`get${router.currentRoute.name == 'admin-product' ? 'Admin' : 'Seller'}Product`, {
                        id: data.id
                    })
                })
                .catch()
        }
    },

}
