import axios from "@axios";

export default {
    namespaced: true,
    state: {
        account: {},
        balance: 0,
        loading: true,
        balanceLoading: true
    },
    getters: {
        account(state) {
            return state.account
        },
        loading(state) {
            return state.loading
        },
        balance(state) {
            return state.balance
        },
        balanceLoading(state) {
            return state.balanceLoading
        },

    },
    mutations: {
        SET_ACCOUNT(state, value) {
            state.account = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_BALANCE(state, value) {
            state.balance = value
        },
        SET_BALANCE_LOADING(state, value) {
            state.balanceLoading = value
        }
    },
    actions: {
        getBalance(ctx, data = {}) {
            ctx.commit('SET_BALANCE_LOADING', true)
            axios.get('balance').then(res => {
                ctx.commit('SET_BALANCE', res.data)
                ctx.commit('SET_BALANCE_LOADING', false)
            })
        },
        getAccount(ctx, data = {}){
            ctx.commit('SET_LOADING', true)
            axios.get('auth/user-account')
                .then(res => {
                    ctx.commit('SET_ACCOUNT', res.data.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch(err => {})
        }
    },

}
