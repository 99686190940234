import {mdiLogoutVariant, mdiHelpBox} from "@mdi/js";

export default [
    {
        subheader: true,
        action: 'read',
    },
    {
        title: 'Help Center',
        icon: mdiHelpBox,
        href: 'https://help.sellerassistant.deals/en/',
        action: 'read'
    },
    {
        title: 'Logout',
        icon: mdiLogoutVariant,
        to: 'logout',
        action: 'read'
    }
]