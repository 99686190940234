export const cardGoToCheckoutUnsigned = {
    name: 'Click Go To Checkout',
    props: {unsigned: true}
}
export const cardGoToCheckout = {
    name: 'Click Go To Checkout'
}

export const buyNowClicked = {
    name: 'Click Buy Now'
}

export function removeProductFromMiniCart(id) {
    return {
        name: 'Product Removed From Mini Cart',
        props: {productId: id}
    }
}

export function removeProductFromCheckout(id) {
    return {
        name: 'Product Removed From Checkout',
        props: {productId: id}
    }
}

export const miniCardOpenClicked = {
    name: 'Mini Cart Opened'
}

export function pageView(to) {
    return {
        name: 'Page View',
        props: {'route-name': to.name, route: to.path}
    }
}

export const topUpDialogOpened = {
    name: "Top Up Dialog Opened"
}

export function addNewCardStarted(resource) {
    return {
        name: 'Add New Card Clicked',
        props: {resource}
    }
}

export const TopUpButtonClicked = {
    name: 'Top Up Pay Clicked'
}

export function checkoutBuyItemsClicked(ids, useBalance) {
    return {
        name: 'Checkout Buy Items Clicked',
        props: {ids, useBalance}
    }
}

export function productAddedToCart(id) {
    return {
        name: 'Product Added To Cart',
        props: {productId: id}
    }
}