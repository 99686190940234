const appConfig  = {
    websocketsKey: process.env.VUE_APP_WSKEY,
    websocketsServer: process.env.VUE_APP_WSSERVER,
    googleId: process.env.VUE_APP_GOOGLEID,
    facebookId: process.env.VUE_APP_FBID,
    broadcastingAuthUrl: process.env.VUE_APP_BROADCASTAUTH,
    stripePubKey: process.env.VUE_APP_STRIPEPUBKEY,
    stripeAcc: process.env.VUE_APP_STRIPEACCT,
    gTag: process.env.VUE_APP_GTAG,
    intercomId: process.env.VUE_APP_INTERCOMID,
    amplitudeApiKey: process.env.VUE_APP_AMPLITUDEAPIKEY,
    mixPanelToken: process.env.VUE_APP_MIXPANELTOKEN,
    backEndUrl: process.env.VUE_APP_BACKENDURL
}

export default appConfig
