import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import appRoutes from './App/routes'
import {logEventAmplitude} from '@/plugins/tracking/amplitude'
import {logEventMixPanel} from "@/plugins/tracking/mixpanel";
import {pageView} from "@/plugins/tracking/events";
Vue.use(VueRouter)

const routes = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...appRoutes,
  {
    path: '/',
    beforeEnter(to, _, next) {
      const userData = localStorage.getItem('userData')
      const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
      if(isLoggedIn){
        next({name: 'products'})
      } else {
        next({ name: 'marketplace-unsigned' })
      }
    },
    meta: {
      resource: 'Public',
    },
  },

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // savedPosition is only available for popstate navigations.
    if (savedPosition || to.name == 'admin-products-list' || to.name == 'products') return savedPosition

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.params.savePosition) return {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      let position = {selector: to.hash}

      // specify offset of the element
      // if (to.hash === '#anchor2') {
      //   position.offset = { y: 100 }
      // }
      return position
    }

    // scroll to top by default
    return {x: 0, y: 0}
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  if(to.name != _.name) {
    let event = pageView(to)
    logEventAmplitude(event.name, event.props)
    logEventMixPanel(event.name, event.props)
  }
  const userData = localStorage.getItem('userData')

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userAbility')

      store.commit("nav/SET_IS_AMAZON_ALERT", true)
      store.commit("filter/CLEAR_SAVED_FILTER_DATA")

      return next({name: 'auth-login', query: {marketplace: to.query.marketplace}})
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
