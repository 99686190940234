// axios
import axios from 'axios'
import router from '@/router'
import store from "@/store"
import Vue from 'vue'
import appConfig from "@appConfig";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: appConfig.backEndUrl,
  timeout: 30000,
  headers: {common:{"Access-Control-Allow-Origin": "*"}}
})


const interceptResponse = (res) => {
  return Promise.resolve(res)
}
const interceptResErrors = (err) => {
  if(err.response?.status == 401) {
    router.push({ name: 'logout' }).catch(() => {})
  }
  return Promise.reject(err)
}

axiosIns.interceptors.response.use(interceptResponse, interceptResErrors)
axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
