import axios from "@axios";

export default {
    namespaced: true,
    state: {
        notifications: [],
        loading: true,
        unread_count: 0,
        upgrade_to_seller_request_count: 0,
        refunds_escalated_to_admin_count: 0,
        refunds_requested_count: 0,
        rejected_products_count: 0,
        products_on_review_count: 0
    },
    getters: {
        notifications(state) {
            return state.notifications
        },
        upgrade_to_seller_request_count(state) {
            return state.upgrade_to_seller_request_count
        },
        refunds_escalated_to_admin_count(state) {
            return state.refunds_escalated_to_admin_count
        },
        refunds_requested_count(state) {
            return state.refunds_requested_count
        },
        rejected_products_count(state) {
            return state.rejected_products_count
        },
        products_on_review_count(state) {
            return state.products_on_review_count
        },
    },
    mutations: {
        SET_NOTIFICATIONS(state, value) {
            state.notifications = value
        },
        SET_UNREAD_COUNT(state, value) {
            state.unread_count = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_UPGRADE_TO_SELLER_REQUEST_COUNT(state, value)
        {
            state.upgrade_to_seller_request_count = value
        },
        SET_REFUNDS_ESCALATED_TO_ADMIN_COUNT(state, value)
        {
            state.refunds_escalated_to_admin_count = value
        },
        SET_REFUNDS_REQUESTED_COUNT(state, value)
        {
            state.refunds_requested_count = value
        },
        SET_REJECTED_PRODUCTS_COUNT(state, value)
        {
            state.rejected_products_count = value
        },
        SET_PRODUCTS_ON_REVIEW_COUNT(state, value)
        {
            state.products_on_review_count = value
        }
    },
    actions: {
        getNotifications(ctx, data) {
            ctx.commit('SET_LOADING', true)
            axios.get(`notifications`, {params: {paginate: data.paginate}})
                .then(res => {
                    ctx.commit('SET_NOTIFICATIONS', res.data.data)
                    ctx.commit('SET_LOADING', false)
                    ctx.commit('SET_UNREAD_COUNT', res.data.unread_count)
                })
                .catch()
        },
        getNavigationNotificationsCount(ctx, data = {})
        {
            axios.get('all-requests-count').then(res => {
                ctx.commit('SET_UPGRADE_TO_SELLER_REQUEST_COUNT', res.data.upgradeToSellerRequestCount)
                ctx.commit('SET_REFUNDS_ESCALATED_TO_ADMIN_COUNT', res.data.refundsEscalatedToAdminCount)
                ctx.commit('SET_REFUNDS_REQUESTED_COUNT', res.data.refundsRequestedCount)
                ctx.commit('SET_REJECTED_PRODUCTS_COUNT', res.data.rejectedProductsCount)
                ctx.commit('SET_PRODUCTS_ON_REVIEW_COUNT', res.data.productsOnReviewCount)
            })
        }
    },

}
