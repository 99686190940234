import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import store from "@/store";

export function runEvents() {

    const vm = getCurrentInstance().proxy

    const userData = localStorage.getItem('userData')
    const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

    function notifyEvent(title, message, type = '') {
        vm.$notify({
            group: 'deals',
            title: title,
            text: vm.$t(message),
            type: type
        })
    }

    if (isLoggedIn) {
        vm.$echo.private(`user.${JSON.parse(userData).id}`)
            .listen('.refund-created', data => {
                store.dispatch('notifications/getNavigationNotificationsCount')
                notifyEvent(vm.$t('Refund'), 'order refund requested', 'info')
            })
            .listen(".payment", (data) => {
                switch (data.id) {
                    case 0:
                        notifyEvent(vm.$t('Payment'), 'payment in progress', 'info')
                        break;
                    case 1:
                        notifyEvent(vm.$t('Payment'), 'paid successfully', 'success')
                        store.dispatch('account/getBalance')
                        break;
                }
            })
            .listen(".product-approval", (data) => {
                switch (data.status) {
                    case 2:
                        notifyEvent(vm.$t('Product'), 'product approved', 'success')
                        break;
                    case 3:
                        notifyEvent(vm.$t('Product'), 'product declined, check details in product list', 'warning')
                        store.dispatch('notifications/getNavigationNotificationsCount')
                        break;
                    case 4:
                        notifyEvent(vm.$t('Product'), 'product banned', 'error')
                        store.dispatch('notifications/getNavigationNotificationsCount')
                        break;
                }
            })
            .listen(".seller-approval", (data) => {
                switch (data.status) {
                    case 1:
                        notifyEvent(vm.$t('seller update request'), 'seller update approved', 'success')
                        axios.get('/auth/me').then(response => {
                            const user = response.data.data
                            const {ability: userAbility} = user
                            vm.$ability.update(userAbility)
                            localStorage.setItem('userAbility', JSON.stringify(userAbility))
                        })
                        break;
                    case 2:
                        notifyEvent(vm.$t('seller update request'), 'seller update declined', 'error')
                        break;
                }
            })
            .listen(".import-finished", (data) => {
                switch (data.status) {
                    case 3:
                        store.dispatch('imports/getImports', {})
                        notifyEvent(vm.$t('Import Finished'), 'Import products finished successfully', 'info')
                        break;
                    case 4:
                        store.dispatch('imports/getImports', {})
                        notifyEvent(vm.$t('Import Error'), 'Import products finished with error', 'error')
                        break;
                }
            })
            .listen(".product-created", (data) => {
                notifyEvent(data.asin, 'Product created, ID: ' + data.id, 'success')   
            })
            .listen(".product-updated", (data) => {
                notifyEvent(data.asin, 'Product updated, ID: ' + data.id, 'success')   
            })
            .listen(".import-product-fail", (data) => {
                notifyEvent(data.asin, 'Import ASIN: ' + data.asin + ' finished with error', 'error')   
            })
            .listen(".restriction", (data) => {
                store.dispatch('product/addRestriction', data)
            })
    }
    if (vm.$can('all products view')) {
        vm.$echo.private(`admin`)
            .listen(".product-on-review", (data) => {
                notifyEvent(vm.$t('Product Review'), 'New product added to review list', 'info')
                store.dispatch('notifications/getNavigationNotificationsCount')
            })
            .listen(".seller-request", (data) => {
                notifyEvent(vm.$t('Seller Request'), 'New seller request added to review list', 'info')
                store.dispatch('notifications/getNavigationNotificationsCount')
            }).listen('.escalate-to-admin', data => {
                notifyEvent(vm.$t('Refund'), 'refund escalated to admin', 'info')
                store.dispatch('notifications/getNavigationNotificationsCount')
            })
    }
}
