<template>
    <div class="deals-role-title mb-4" :class="{'align-end role-scale': $vuetify.breakpoint.mdAndDown && !menuIsVerticalNavMini, 'deals-role-title-loaded': loaded}" v-if="role">
        <span class="account">{{ $t("account") }}</span>
        <span class="role">{{ role }}</span>
    </div>
</template>

<script>
import {onMounted, onUnmounted,ref} from "@vue/composition-api";
import useAppConfig from '@core/@app-config/useAppConfig'
export default {
    props: {
        role: {
            type: String,
            required: true
        }
    },
    setup() {
        const { menuIsVerticalNavMini } = useAppConfig()
        const loaded = ref(false)
        onMounted(() => {
           setTimeout(() => loaded.value = true, 500) 
        })
        onUnmounted(() => loaded.value = false)
        return {
            loaded,
            menuIsVerticalNavMini
        }
    }
}
</script>
