import store from "@/store";
// import { uFirstLetter, trimString } from "@/plugins/helpers"

const routes = [
    {
        path: '/admin/users/:tab',
        name: 'users',
        component: () => import('@/views/cabinet/user/Users'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Users'),
            //             to: { name: 'users', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab))
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/seller/dashboard',
        name: 'dashboard',
        component: () => import('@/views/cabinet/dashboard/Dashboard'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        component: () => import('@/views/cabinet/dashboard/AdminDashboard'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/seller/products/:tab',
        name: 'seller-products',
        component: () => import('@/views/cabinet/product/Products'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Inventory'),
            //             to: { name: 'seller-products', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/seller/product/:id',
        name: 'seller-product',
        component: () => import('@/views/cabinet/product/product-view/ProductView'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Inventory'),
            //             to: { name: 'seller-products', params: { tab: 'all' } },
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(trimString(store.state.product.product.title, 100))),
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/admin/product/:id',
        name: 'admin-product',
        component: () => import('@/views/cabinet/product/product-view/ProductView'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Inventory'),
            //             to: { name: 'admin-products-list', params: { tab: 'all' } },
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(trimString(store.state.product.product.title, 100))),
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/account/:tab',
        name: 'user-account',
        component: () => import('@/views/cabinet/account/Account'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Account'),
            //             to: { name: 'user-account', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/seller/store/:tab',
        name: 'user-market',
        component: () => import('@/views/cabinet/store/Market'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Store Account'),
            //             to: { name: 'user-market', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/admin/upgrade-requests',
        name: 'seller-requests',
        component: () => import('@/views/cabinet/rolesAndPermissions/AcceptSellerList'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Upgrade Requests'), 
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/admin/settings/:tab',
        name: 'roles-and-permissions',
        component: () => import('@/views/cabinet/rolesAndPermissions/RolesAndPermissions'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('MP Settings'), 
            //             to: { name: 'roles-and-permissions', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/storefront',
        name: 'products',
        component: () => import('@/views/market/ProductsList'),
        beforeEnter(to, _, next) {
            const userData = localStorage.getItem('userData')
            const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
            if (!isLoggedIn) {
                next({name: 'marketplace-unsigned', query: {marketplace: to.query.marketplace}})
            } else {
                next()
            }
        },
        meta: {
            resource: 'Public',
            layout: 'content',
            user: 'signed',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Store'),
            //             to: { name: 'products' }
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/storefront',
        name: 'marketplace-unsigned',
        component: () => import('@/views/market/ProductsList'),
        meta: {
            layout: 'unsigned',
            user: 'unsigned',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/store/:slug',
        name: 'store-products',
        component: () => import('@/views/market/StoreProducts'),
        beforeEnter(to, _, next) {
            const userData = localStorage.getItem('userData')
            const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
            if (!isLoggedIn) {
                next({name: 'store-products-unsigned', params: {slug: to.params.slug}})
            } else {
                next()
            }
        },
        meta: {
            resource: 'Public',
            layout: 'content',
            user: 'signed'
        },
    },
    {
        path: '/store/:slug',
        name: 'store-products-unsigned',
        component: () => import('@/views/market/StoreProducts'),
        meta: {
            resource: 'Public',
            layout: 'unsigned',
            user: 'unsigned'
        },
    },
    {
        path: '/product/:id',
        name: 'product',
        component: () => import('@/views/market/ProductView'),
        beforeEnter: (to, _, next) => {
            const userData = localStorage.getItem('userData')
            const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
            if (!isLoggedIn) {
              next({name: 'product-unsigned', params: {id: to.params.id}})
            } else {
              next()
            }
        },
        meta: {
            resource: 'Public',
            layout: 'content',
            user: 'signed',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('store'),
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t(store.state.product.product.market),
            //             to: { name: 'store-products',params: { slug: store.state.product.product.market_slug } }
            //         },
            //         {
            //             text: this.$i18n.t('Product details'),
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/product/:id',
        name: 'product-unsigned',
        component: () => import('@/views/market/ProductView'),
        meta: {
            resource: 'Public',
            layout: 'unsigned',
            user: 'unsigned',
        }
    },

    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/views/market/cart/Cart'),
        meta: {
            layout: 'content',
            user: 'signed',

            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Store'),
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Shopping cart'),
            //             to: { name: 'checkout' }
            //         },
            //     ]
            // }
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout'),
        meta: {
            layout: 'content',
        },
    },

    {
        path: '/orders',
        name: 'orders-list',
        component: () => import('@/views/cabinet/order/order-list/RefundsAndOrders'),
        meta: {
            layout: 'content',
        //     breadCrumb() {
        //         return [
        //             {
        //                 text: 'Deals',
        //                 to: { name: 'products' }
        //             },
        //             {
        //                 text: this.$i18n.t('Orders'),
        //                 to: { name: 'orders-list' },
        //                 disabled: true
        //             },
        //             {
        //                 text: this.$i18n.t('All Orders'),
        //             },
        //         ]
        //     }
        }
    },
    {
        path: '/refunds',
        name: 'refunds-list',
        component: () => import('@/views/cabinet/order/order-list/RefundsAndOrders'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Orders'),
            //             to: { name: 'orders-list' },
            //             disabled: true
            //         },
            //         {
            //             text: this.$i18n.t('Refund Requests'),
            //         },
            //     ]
            // }
        }
    },
    {
        path: '/order/:id',
        name: 'order',
        component: () => import('@/views/cabinet/order/order-view/Order'),
        meta: {
            layout: 'content'
        }
    },
    {
        path: '/seller/orders',
        name: 'seller-orders-list',
        component: () => import('@/views/cabinet/order/order-list/SellerOrderList'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Orders'),
            //             to: { name: 'seller-orders-list' }
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/seller/refunds/:tab',
        name: 'seller-refunds-list',
        component: () => import('@/views/cabinet/order/order-list/SellerRefundList'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Refunds'),
            //             to: { name: 'seller-refunds-list' },
            //             disabled: true
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/seller/order/:id',
        name: 'seller-order',
        component: () => import('@/views/cabinet/order/order-view/SellerOrder'),
        meta: {
            layout: 'content'
        }
    },
    {
        path: '/admin/user/:user_id(\\d+)?/orders',
        name: 'admin-orders-user-list',
        component: () => import('@/views/cabinet/order/order-list/AdminOrderList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/orders',
        name: 'admin-orders-list',
        component: () => import('@/views/cabinet/order/order-list/AdminOrderList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/store/:store_id(\\d+)?/orders',
        name: 'admin-orders-store-list',
        component: () => import('@/views/cabinet/order/order-list/AdminOrderList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/refunds/:tab',
        name: 'admin-refunds-list',
        component: () => import('@/views/cabinet/order/order-list/AdminRefundsList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/user/:user_id(\\d+)?/refunds/:tab',
        name: 'admin-refunds-user-list',
        component: () => import('@/views/cabinet/order/order-list/AdminRefundsList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/store/:store_id(\\d+)?/refunds/:tab',
        name: 'admin-refunds-store-list',
        component: () => import('@/views/cabinet/order/order-list/AdminRefundsList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/store/:store_id(\\d+)?/inventory/:tab',
        name: 'admin-products-user-store-list',
        component: () => import('@/views/cabinet/product/Products'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/user/:user_id(\\d+)?/transactions',
        name: 'admin-transactions-user-list',
        component: () => import('@/views/cabinet/transactions/Transactions'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/store/:store_id(\\d+)?/transactions/:tab',
        name: 'admin-transactions-user-store-list',
        component: () => import('@/views/cabinet/transactions/StoreTransactions'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/inventory/:tab',
        name: 'admin-products-list',
        component: () => import('@/views/cabinet/product/product-list/AdminProductsList'),
        props: route => ({ search: store.state.product.search }),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Inventory'),
            //             to: { name: 'admin-products-list' },
            //             disabled: true
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/admin/order/:id',
        name: 'admin-order',
        component: () => import('@/views/cabinet/order/order-view/AdminOrder'),
        meta: {
            layout: 'content'
        }
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/cabinet/transactions/Transactions'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Transactions'),
            //             to: { name: 'transactions' }
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/seller/transactions/:tab',
        name: 'store-transactions',
        component: () => import('@/views/cabinet/transactions/StoreTransactions'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Transactions'),
            //             to: { name: 'store-transactions', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/admin/suppliers-blacklist',
        name: 'admin-suppliers-blacklist',
        component: () => import('@/views/cabinet/suppliers/AdminSuppliersBlackList'),
        meta: {
            layout: 'content',
        }
    },
    {
        path: '/admin/transactions/:tab',
        name: 'admin-transactions',
        component: () => import('@/views/cabinet/transactions/admin/AdminTransactionsMain'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Transactions'),
            //             to: { name: 'admin-transactions', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/admin/payouts/:tab',
        name: 'admin-payouts',
        component: () => import('@/views/cabinet/payouts/Payouts'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Payouts'),
            //             to: { name: 'admin-payouts', params: { tab: this.$route.params.tab } },
            //             disabled: true,
            //         },
            //         {
            //             text: this.$i18n.t(uFirstLetter(this.$route.params.tab)),
            //         }
            //     ]
            // }
        }
    },
    {
        path: '/admin/user/:id/:tab?',
        name: 'user',
        component: () => import('@/views/cabinet/user/User'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: { name: 'products' }
            //         },
            //         {
            //             text: this.$i18n.t('Users'),
            //             to: { name: 'users', params: { tab: this.$route.params.tab ? this.$route.params.tab : 'all' } }
            //         },
            //         {
            //             text: store.state.profile.profile.name,
            //         }
            //     ]
            // }
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/views/pages/Notifications'),
        meta: {
            layout: 'content',
            // breadCrumb() {
            //     return [
            //         {
            //             text: 'Deals',
            //             to: {name: 'products'}
            //         },
            //     ]
            // }
        }
    }
]
export default routes