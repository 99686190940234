export default {
    namespaced: true,
    state: {
        isVerticalNavMini: true,
        globalItemsPerPage: 10,
        itemsPerPageOptions: [10, 50, 100],
        isAmazonAlert: true,
        isPayoutAlert: false,
        isPayoutAlertInvalid: false
    },
    getters: {
        isVerticalNavMini(state) {
            return state.isVerticalNavMini
        },
        globalItemPerPage(state) {
            return state.globalItemsPerPage
        },
        itemsPerPageOptions(state) {
            return state.itemsPerPageOptions
        },
        isAmazonAlert(state) {
            return state.isAmazonAlert
        },
        isPayoutAlert() {
            return state.isPayoutAlert
        },
        isPayoutAlertInvalid() {
            return state.isPayoutAlertInvalid
        }
    },
    mutations: {
        TOGGLE_MENU_VERTICAL_NAV_MINI(state, data) {
            state.isVerticalNavMini = data
        },
        SET_GLOBAL_ITEMS_PER_PAGE(state, data) {
            state.globalItemsPerPage = data
        },
        SET_ITEMS_PER_PAGE_OPTIONS(state, data) {
            state.itemsPerPageOptions = data
        },
        SET_IS_AMAZON_ALERT(state, data) {
            state.isAmazonAlert = data
        },
        SET_IS_PAYOUT_ALERT(state, data) {
            state.isPayoutAlert = data
        },
        SET_IS_PAYOUT_ALERT_INVALID(state, data) {
            state.isPayoutAlertInvalid = data
        }
    }
}
