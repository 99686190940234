import axios from "@axios";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        payouts: {},
        pendingPayouts: [],
        paidPayouts: [],
        loading: true,
        statisticPayouts: '-',
        pendingLoading: true,
        totalPending: 0,
        paidLoading: true,
        totalPaid: 0
    },
    getters: {
        payouts(state) {
            return state.payouts
        },
        loading(state) {
            return state.loading
        },
        statisticPayouts(state) {
            return state.statisticPayouts
        },
        pendingLoading(state) {
            return state.pendingLoading
        },
        pendingPayouts(state) {
            return state.pendingPayouts
        },
        totalPending(state) {
            return state.totalPending
        },
        paidLoading(state) {
            return state.paidLoading
        },
        paidPayouts(state) {
            return state.paidPayouts
        },
        totalPaid(state) {
            return state.totalPaid
        }
    },
    mutations: {
        SET_PAYOUTS(state, value) {
            state.payouts = value
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        SET_STATISTIC_PAYOUTS(state, value) {
            state.statisticPayouts = value
        },
        SET_PENDING_PAYOUTS(state, val) {
            state.pendingPayouts = val
        },
        SET_PENDING_LOADING(state, val) {
            state.pendingLoading = val
        },
        SET_TOTAL_PENDING(state, val) {
            state.totalPending = val
        }
        ,
        SET_PAID_PAYOUTS(state, val) {
            state.paidPayouts = val
        },
        SET_PAID_LOADING(state, val) {
            state.paidLoading = val
        },
        SET_TOTAL_PAID(state, val) {
            state.totalPaid = val
        }
    },
    actions: {
        getPayouts(ctx, data) {
            //admin Payouts
        },
        getAdminPayoutsDashData(ctx) {
            ctx.commit('SET_LOADING', true)
            axios.get('admin/dashboard/payouts')
                .then(res => {
                    ctx.commit('SET_STATISTIC_PAYOUTS', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getSellerPayoutsDashData(ctx) {
            ctx.commit('SET_LOADING', true)
            axios.get('dashboard/payouts')
                .then(res => {
                    ctx.commit('SET_STATISTIC_PAYOUTS', res.data)
                    ctx.commit('SET_LOADING', false)
                })
                .catch()
        },
        getPendingPayouts(ctx, data) {
            const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            let params = {
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                status_tab: 'pending',
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'DESC' : 'ASC'
            }
            ctx.commit('SET_PENDING_LOADING', true)
            axios.get('admin/payout-list', {params}).then(res => {
                ctx.commit('SET_PENDING_PAYOUTS', res.data.data)
                ctx.commit('SET_TOTAL_PENDING', res.data.meta.total)
                let ob = {}
                ob[params.status_tab] = {
                    itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                    page,
                    lastPage: res.data.meta.last_page
                }
                ctx.commit("pagination/SET_PAYOUTS_PAGINATION", ob, {root: true})
                ctx.commit('SET_PENDING_LOADING', false)
            })
        },
        getPaidPayouts(ctx, data) {
            const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = data
            let params = {
                pagination: ctx.rootGetters["nav/globalItemPerPage"],
                page,
                status_tab: 'paid',
                sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
                sort_dir: sortDesc && sortDesc[0] ? 'DESC' : 'ASC'
            }
            ctx.commit('SET_PAID_LOADING', true)
            axios.get('admin/paid-payout-list', {params}).then(res => {
                ctx.commit('SET_PAID_PAYOUTS', res.data.data)
                ctx.commit('SET_TOTAL_PAID', res.data.meta.total)
                let ob = {}
                ob[params.status_tab] = {
                    itemsPerPage: ctx.rootGetters["nav/globalItemPerPage"],
                    page,
                    lastPage: res.data.meta.last_page
                }
                ctx.commit("pagination/SET_PAYOUTS_PAGINATION", ob, {root: true})
                ctx.commit('SET_PAID_LOADING', false)
            })
        }
    },

}
