import amplitude from 'amplitude-js';
import appConfig from "@appConfig";
import store from "@/store";

let initialized = false

export function setUserProperties() {
    if (appConfig.amplitudeApiKey) {
        const userResource = store.state.user.userResource
        amplitude.getInstance().setUserId(userResource?.id)
        amplitude.getInstance().setUserProperties(userResource);
    }
}

export function initAmplitude() {
    if (appConfig.amplitudeApiKey) {
        amplitude.getInstance().init(appConfig.amplitudeApiKey, '', {includeUtm: true})
        initialized = true
    }
}

export function logEventAmplitude(event, props = {}) {
    if (appConfig.amplitudeApiKey) {
        let trackEvent = setInterval(function () {
            if (initialized) {
                clearInterval(trackEvent);
                amplitude.getInstance().logEvent(event, props)
            }
        }, 500)
    }
}