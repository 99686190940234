export default {
    namespaced: true,
    state: {
        /** Store - ProductsTable.vue  */
        store: {},
        /** Seller Inventory - ProductsTable.vue  */
        products: {
            all: {},
            active: {},
            inactive: {},
            drafts: {},
            rejected: {},
            uploaded: {}
        },
        /** Admin Users - UsersTable.vue  */
        users: {
            all: {},
            buyer: {},
            seller: {},
            moderator: {}
        },
        /** Admin Inventory - AdminProductsTable.vue  */
        adminProducts: {
            all: {},
            active: {},
            inactive: {},
            drafts: {},
            rejected: {},
            unverified: {},
            'unverified-new': {}
        },
        /** Admin Upgrade Requests - AcceptSellerList.vue  */
        upgradeRequests: {},
        /** Buyer Orders - OrdersTable.vue  */
        "orders-list": {},
        /** Buyer Refunds - OrdersTable.vue  */
        "refunds-list": {},
        /** Buyer Transactions - Transactions.vue  */
        transactions: {},
        /** Admin Suppliers - AdminSuppliersBlackList.vue  */
        suppliers: {},
        /** Seller Transactions - StoreTransactions.vue  */
        sellerTransactions: {
            all: {},
            orders: {},
            refunds: {},
            payouts: {}
        },
        /** Admin Transactions - AdminTransactionsTable.vue  */
        adminTransactions: {
            all: {},
            "top-ups": {},
            orders: {},
            refunds: {},
            payouts: {},
            "mp-profit": {},
        },
        /** Seller Orders - OrdersTable.vue  */
        "seller-orders-list": {},
        /** Seller Refunds - OrdersTable.vue  */
        "seller-refunds-list": {
            all: {},
            "refund-requests": {},
            refunded: {}
        },
        /** Admin Orders - OrdersTable.vue  */
        "admin-orders-list": {},
        "admin-orders-user-list": {},
        "admin-orders-store-list":{},
        /** Admin Refunds - OrdersTable.vue  */
        "admin-refunds-list": {
            all: {},
            "refund-requests": {},
            escalated: {},
            refunded: {}
        },
        "admin-refunds-user-list": {
            all: {},
            "refund-requests": {},
            escalated: {},
            refunded: {}
        },
        "admin-refunds-store-list": {
            all: {},
            "refund-requests": {},
            escalated: {},
            refunded: {}
        },
        payouts: {
            pending: {},
            paid: {}
        },
    },
    getters: {
        upgradeRequests(state) {
            return state.upgradeRequests
        },
        store(state) {
            return state.store
        },
        products(state) {
            return state.products
        },
        users(state) {
            return state.users
        },
        adminProducts(state) {
            return state.adminProducts
        },
        "orders-list"(state) {
            return state["orders-list"]
        },
        "refunds-list"(state) {
            return state["refunds-list"]
        },
        suppliers(state) {
            return state.suppliers
        },
        transactions(state) {
            return state.transactions
        },
        sellerTransactions(state) {
            return state.sellerTransactions
        },
        adminTransactions(state) {
            return state.adminTransactions
        },
        "seller-orders-list"(state) {
            return state["seller-orders-list"]
        },
        "seller-refunds-list"(state) {
            return state["seller-refunds-list"]
        },
        "admin-orders-list"(state) {
            return state["admin-orders-list"]
        },
        "admin-orders-user-list"(state) {
            return state["admin-orders-user-list"]
        },
        "admin-orders-store-list"(state) {
            return state["admin-orders-store-list"]
        },
        "admin-refunds-list"(state) {
            return state["admin-refunds-list"]
        },
        "admin-refunds-user-list"(state) {
            return state["admin-refunds-user-list"]
        },
        "admin-refunds-store-list"(state) {
            return state["admin-refunds-store-list"]
        },
        payouts(state) {
            return state.payouts
        }
    },
    mutations: {
        SET_UPGRADE_REQUESTS_PAGINATION(state, data) {
            state.upgradeRequests = Object.assign({}, state.upgradeRequests, data)
        },
        SET_STORE_PAGINATION(state, data) {
            state.store = Object.assign({}, state.store, data)
        },
        SET_PRODUCTS_PAGINATION(state, data) {
            state.products = Object.assign({}, state.products, data)
        },
        SET_USERS_PAGINATION(state, data) {
            state.users = Object.assign({}, state.users, data)
        },
        SET_ADMIN_PRODUCTS_PAGINATION(state, data) {
            state.adminProducts = Object.assign({}, state.adminProducts, data)
        },
        SET_ORDERS_LIST_PAGINATION(state, data) {
            state["orders-list"] = Object.assign({}, state["orders-list"], data)
        },
        SET_REFUNDS_LIST_PAGINATION(state, data) {
            state["refunds-list"] = Object.assign({}, state["refunds-list"], data)
        },
        SET_SUPPLIERS_PAGINATION(state, data) {
            state.suppliers = Object.assign({}, state.suppliers, data)
        },
        SET_TRANSACTIONS_PAGINATION(state, data) {
            state.transactions = Object.assign({}, state.transactions, data)
        },
        SET_SELLER_TRANSACTIONS_PAGINATION(state, data) {
            state.sellerTransactions = Object.assign({}, state.sellerTransactions, data)
        },
        SET_ADMIN_TRANSACTIONS_PAGINATION(state, data) {
            state.adminTransactions = Object.assign({}, state.adminTransactions, data)
        },
        SET_SELLER_ORDERS_LIST_PAGINATION(state, data) {
            state["seller-orders-list"] = Object.assign({}, state["seller-orders-list"], data)
        },
        SET_SELLER_REFUNDS_LIST_PAGINATION(state, data) {
            state["seller-refunds-list"] = Object.assign({}, state["seller-refunds-list"], data)
        },
        SET_ADMIN_ORDERS_LIST_PAGINATION(state, data) {
            state["admin-orders-list"] = Object.assign({}, state["admin-orders-list"], data)
        },
        SET_ADMIN_ORDERS_USER_LIST_PAGINATION(state, data) {
            state["admin-orders-user-list"] = Object.assign({}, state["admin-orders-user-list"], data)
        },
        SET_ADMIN_ORDERS_STORE_LIST_PAGINATION(state, data) {
            state["admin-orders-store-list"] = Object.assign({}, state["admin-orders-store-list"], data)
        },
        SET_ADMIN_REFUNDS_LIST_PAGINATION(state, data) {
            state["admin-refunds-list"] = Object.assign({}, state["admin-refunds-list"], data)
        },
        SET_ADMIN_REFUNDS_USER_LIST_PAGINATION(state, data) {
            state["admin-refunds-user-list"] = Object.assign({}, state["admin-refunds-user-list"], data)
        },
        SET_ADMIN_REFUNDS_STORE_LIST_PAGINATION(state, data) {
            state["admin-refunds-store-list"] = Object.assign({}, state["admin-refunds-store-list"], data)
        },
        SET_PAYOUTS_PAGINATION(state, data) {
            state.payouts = Object.assign({}, state.payouts, data)
        }
    },
    actions: {

    },
}
